import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { CategoryProps } from '../constants';

let categoriesData: CategoryProps[] = [];

const useTourCategories = (refresh?: number | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<CategoryProps[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      if (categoriesData.length > 0 && !refresh) {
        setCategories(categoriesData);
      }
      setIsLoading(true);
      const res = await DAV_APIS.country.get.getCategories();
      if (res.status === 200) {
        setCategories(res.data.categories);
        categoriesData = res.data.categories;
      }
      setIsLoading(false);
    };
    fetchCategories();
  }, [refresh]);
  return { categories, loading: isLoading };
};
export default useTourCategories;
