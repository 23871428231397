import { toast } from 'react-toastify';

interface AlertOptions {
  variant: 'success' | 'error' | 'info' | 'warning';
  message: string;
  options?: object;
}

/**
 * @name Alert
 * @param {"success" | "error" | "info" | "warning"} variant
 * @param {string} message
 * @param {Object} options
 */
const Alert = ({ variant, message, options }: AlertOptions) =>
  toast[variant](message, options);

interface AlertUpdateOptions {
  id: string;
  options: object;
}

/**
 * @name AlertUpdate
 * @param {string} id
 * @param {Object} options
 */
export const AlertUpdate = ({ id, options }: AlertUpdateOptions) =>
  toast.update(id, options);

export default Alert;
