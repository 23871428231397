import { Formik } from 'formik';
import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { postSchema } from '../../../../../constants';
import PostForm from '../sections/PostForm';
import { DAV_APIS } from '../../../../../adapters';
import { Alert, SEO } from '../../../../commons';

const NewPostPage: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<'post' | 'language'>('post');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const newTourSubmitHandler = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        name: values.title,
        post_content: values.postContent,
        file: values.imageFile,
        post_blocks: JSON.stringify(values.postBlocks),
      };
      const res = await DAV_APIS.post.createNewPost(data, selectedCategory);

      if (res.status === 201) {
        Alert({ variant: 'success', message: 'Post package created successfully' });
        navigate('/dashboard/safari-updates');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Alert({ variant: 'error', message: 'Failed to create post, Please try again' });
    }
  };

  return (
    <div className='w-full py-2 md:p-5'>
      <div className='flex justify-between items-center'>
        <button
          onClick={() => navigate('/dashboard/safari-updates')}
          type='button'
          className='bg-transparent flex gap-1 items-center'
        >
          <MdArrowBackIosNew /> Back
        </button>
        <div>
          <select
            onChange={(e) => {
              setSelectedCategory(e.target.value as 'post' | 'language');
            }}
            value={selectedCategory}
            className='text-primary rounded-md p-2 border border-[#cbd6e2] focus:outline-none focus:border-primary hover:border-primary leading-tight'
          >
            <option value='posts'>Posts</option>
            <option value='language'>Language Posts</option>
          </select>
        </div>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          postContent: '',
          imageCover: '',
          imageFile: '',
          postBlocks: [],
          blockTitle: '',
          blockDescription: '',
        }}
        validationSchema={postSchema}
        onSubmit={async (values, { resetForm }) => {
          await newTourSubmitHandler(values);
          resetForm();
        }}
      >
        {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
          <>
            <div className='bg-white rounded-md p-5 my-5'>
              <h2 className='text-xl font-semibold mb-2 text-center'>Add New Post</h2>
              {selectedCategory === 'language' && (
                <p className='text-sm text-center text-primary'>
                  {' '}
                  **Please use any language of your choice in any of the fields below**
                </p>
              )}

              <div className=''>
                <form onSubmit={handleSubmit}>
                  <PostForm values={values} setFieldValue={setFieldValue} />
                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {loading ? 'Creating...' : 'Create Post'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <SEO
              pageType={selectedCategory === 'post' ? 'post' : 'languagePost'}
              initialData={{
                title: values.title,
                description: values.postContent,
                image: values.imageCover,
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default NewPostPage;
