import React, { useState, useEffect } from 'react';
import { Table, pagesColumns } from '../../../commons/Table';
import { Modal, SEO } from '../../../commons';
import { DAV_APIS } from '../../../../adapters';
import { DAV_MAIN_DOMAIN } from '../../../../constants';

const Pages = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageData, setPageData] = useState<any[]>([]);
  const [record, setRecord] = useState<any>({}); // for edit and view
  const [refresh, setRefresh] = useState(null);

  const handleEdit = (e: any, data: any) => {
    e.stopPropagation();
    setShowModal(true);
    setRecord(data);
  };

  const handleView = (e: any, data: any) => {
    e.stopPropagation();
    if (data.slug === 'home') {
      window.open(`${DAV_MAIN_DOMAIN}`, '_blank');
      return;
    }
    window.open(`${DAV_MAIN_DOMAIN}/${data.slug}`, '_blank');
  };

  useEffect(() => {
    const fetchStaticPages = async () => {
      setLoading(true);
      const res = await DAV_APIS.getSearchEngineDataByPageType('page');
      if (res.status === 200) {
        setPageData(res.data.seos);
      }
      setLoading(false);
    };
    fetchStaticPages();
  }, [refresh]);

  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Static Pages Seo</h1>
      {/* @ts-ignore */}
      <Table
        columns={pagesColumns}
        data={pageData}
        loading={loading}
        onEditHandler={handleEdit}
        onViewHandler={handleView}
        viewExternal
      />
      <Modal
        size='lg'
        open={showModal}
        onClose={() => setShowModal(false)}
        hideCancelButton
        hideSuccessButton
      >
        <SEO
          isModal
          isEdit
          pageType='page'
          setRefresh={setRefresh}
          setShowModal={setShowModal}
          initialData={{
            title: record?.title,
            description: record?.description,
            image: record?.SEOImage,
            slug: record?.slug,
          }}
        />
      </Modal>
    </div>
  );
};

export default Pages;
