import { FC, useState, useEffect } from 'react';
import { CustomEditor, ImageUpload, Input } from '../../../commons';

interface NewCountryFormProps {
  values: any;
  setFieldValue: any;
}
const CountryForm: FC<NewCountryFormProps> = ({ values, setFieldValue }) => {
  const [initialValue, setInitialValue] = useState(values.description);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setFieldValue('imageFile', file);

    const image = URL.createObjectURL(file);
    setFieldValue('imageCover', image);
  };

  useEffect(() => {
    setFieldValue('description', initialValue);
  }, [initialValue]);

  return (
    <div>
      <Input type='text' name='name' label='Country Name' />
      <Input type='text' name='title' label='Country Headline' />

      <CustomEditor
        placeholder='Type country description here...'
        onChange={setInitialValue}
        value={initialValue}
        name='description'
      />
      <div className='grid grid-cols-1 md:grid-cols-3 md:gap-3 items-end'>
        <div className='col-span-2'>
          <Input type='textarea' rows={4} name='countrySummary' label='Country Summary' />
        </div>
        <div className='col-span-1 mt-4 md:mt-0'>
          <ImageUpload tourImage={values.imageCover} tourImageHandler={handleImageChange} />
        </div>
      </div>
    </div>
  );
};

export default CountryForm;
