import { useEffect, useState } from 'react';
import { DAV_APIS } from '../adapters';
import { TourProps } from '../constants';

const useTour = (tourId: string) => {
  const [tour, setTour] = useState<TourProps>({} as TourProps);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTour = async () => {
    if (!tourId) return;
    setIsLoading(true);
    const res = await DAV_APIS.tour.get.getTourById(tourId);
    if (res.status === 200) {
      setTour(res.data.tour);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchTour();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  return { tour, loading: isLoading };
};

export default useTour;
