import { Formik } from 'formik';
import { useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { categorySchema } from '../../../../../constants';
import { DAV_APIS } from '../../../../../adapters';
import { Alert, SEO } from '../../../../commons';
import { useCategory, useSearchQuery } from '../../../../../hooks';
import DashboardLoader from '../../../sections/Loader/DashboardLoader';
import CategoryForm from '../CategoryForm';

const EditCategoryPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const categoryId = useSearchQuery('category') as string;
  const { category, loading: isFetching } = useCategory(categoryId);

  const editCategorySubmitHandler = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        name: values.title,
        description: values.description,
        country: values.country,
        categoryImage: values.imageFile,
        value: values.title,
      };
      const res = await DAV_APIS.country.editCategory(data, categoryId);
      setLoading(false);
      if (res.status === 200) {
        Alert({ variant: 'success', message: 'Category edited successfully' });
        navigate('/dashboard/categories');
      }
    } catch (error) {
      setLoading(false);
      Alert({ variant: 'error', message: 'Failed to edit category, Please try again' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full py-2 md:p-5'>
      <div className='flex justify-between items-center'>
        <button
          onClick={() => navigate('/dashboard/categories')}
          type='button'
          className='bg-transparent flex gap-1 items-center'
        >
          <MdArrowBackIosNew /> Back
        </button>
      </div>
      <div className='bg-white rounded-md p-5 my-5'>
        <h2 className='text-xl font-semibold mb-2 text-center'>Edit Category</h2>

        {isFetching ? (
          <DashboardLoader />
        ) : (
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                title: category.name || '',
                description: category.description || '',
                imageCover: category.tourCategoryImage || '',
                imageFile: '',
                country: category?.country?.id || '',
              }}
              validationSchema={categorySchema}
              onSubmit={async (values, { resetForm }) => {
                await editCategorySubmitHandler(values);
                resetForm();
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <CategoryForm values={values} setFieldValue={setFieldValue} />
                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {loading ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <SEO
        isEdit
        pageType='category'
        initialData={{
          title: category.name,
          description: category.description,
          image: category.tourCategoryImage,
          slug: category.slug,
        }}
      />
    </div>
  );
};

export default EditCategoryPage;
