import { FC, useState } from 'react';
import { Input } from '../../commons';

interface ChangePasswordFormProps {
  isDashboard?: boolean;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  isDashboard = false,
}) => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const handlePasswordVisibility = (name: string) => {
    if (
      name === 'password' ||
      name === 'confirmPassword' ||
      name === 'currentPassword'
    ) {
      setShowPassword((prevState) => ({
        ...prevState,
        [name]: !showPassword[`${name}`],
      }));
    }
  };
  return (
    <>
      <p className='text-center mb-2'>
        We prioritize your account security. Please create a strong and unique
        but easy to remember password.
      </p>
      {isDashboard && (
        <Input
          type={showPassword.currentPassword ? 'text' : 'password'}
          name='currentPassword'
          label='Current Password'
          autoComplete='current-password'
          handlePasswordVisibility={handlePasswordVisibility}
          showEye
        />
      )}
      <Input
        type={showPassword.password ? 'text' : 'password'}
        name='password'
        label={isDashboard ? 'New Password' : 'Password'}
        autoComplete='current-password'
        handlePasswordVisibility={handlePasswordVisibility}
        showEye
      />
      <Input
        type={showPassword.confirmPassword ? 'text' : 'password'}
        name='confirmPassword'
        label={isDashboard ? 'Confirm New Password' : 'Confirm Password'}
        autoComplete='current-password'
        handlePasswordVisibility={handlePasswordVisibility}
        showEye
      />
    </>
  );
};

export default ChangePasswordForm;
