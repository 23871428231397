import { autoAuthenticationSuccess, logout } from '../slices/authSlice';

interface UserDetails {
  token: string;
  expiresIn: number;
  expirationtime: Date;
  user: any;
}

interface AuthTokenDetails {
  token: string;
  expiresIn: number;
  expirationtime: Date;
}

export const logOutTimer = (dispatch: any, timer: number) => {
  setTimeout(() => {
    dispatch(logout());
  }, timer);
};

export const SaveTokenInLocalStorage = (dispatch: any, userDetails: UserDetails) => {
  logOutTimer(dispatch, userDetails.expiresIn);
  const AuthTokenDetails = {
    token: userDetails.token,
    expiresIn: userDetails.expiresIn,
    expirationtime: userDetails.expirationtime,
  };
  localStorage.setItem('AuthToken', JSON.stringify(AuthTokenDetails));
  localStorage.setItem('CurrentUser', JSON.stringify(userDetails.user));
};

export const AutoAuthenticate = (dispatch: any) => {
  const AuthToken = localStorage.getItem('AuthToken');
  const CurrentUser = localStorage.getItem('CurrentUser')!;
  let UserToken = {} as AuthTokenDetails;
  if (!AuthToken) {
    dispatch(logout());
    return;
  }
  UserToken = JSON.parse(AuthToken);
  const expireDate = new Date(UserToken.expirationtime);
  const todaysDate = new Date();
  if (todaysDate > expireDate) {
    dispatch(logout());
    return;
  }
  const data: {
    token: string;
    user: any;
  } = {
    token: UserToken.token,
    user: JSON.parse(CurrentUser),
  };
  dispatch(autoAuthenticationSuccess(data));

  const timer = expireDate.getTime() - todaysDate.getTime();
  logOutTimer(dispatch, timer);
};
