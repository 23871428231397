import { FC } from 'react';

interface LoaderProps {
  isButton?: boolean;
}

const Loader: FC<LoaderProps> = ({ isButton = false }) => (
  <div className={`dav-loader ${isButton ? 'button' : ''}`} />
);

export default Loader;
