import React from 'react';
import { Table, bookingsColumns } from '../../../commons/Table';
import { useBookings } from '../../../../hooks';

const Bookings = () => {
  const { bookings, loading } = useBookings();
  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Manage Bookings</h1>
      {/* @ts-ignore */}
      <Table columns={bookingsColumns} data={bookings} loading={loading} hideActions />
    </div>
  );
};

export default Bookings;
