// eslint-disable no-param-reassign

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  token: null,
  isLoggedIn: false,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    autoAuthenticationSuccess(state, { payload }) {
      state.user = payload.user;
      state.token = payload.token;
      state.isLoggedIn = !!state.token;
    },
    authenticationSuccess(state, { payload }) {
      state.user = payload.user;
      state.token = payload.token;
      state.isLoggedIn = !!state.token;
    },
    logout(state) {
      state.user = {};
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem('AuthToken');
      localStorage.removeItem('CurrentUser');
    },
  },
});
const { reducer, actions } = authSlice;

export const { autoAuthenticationSuccess, authenticationSuccess, logout } = actions;

export default reducer;
