import { Field, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { seoSchema } from '../../../constants';
import Input from '../InputField';
import NewKeyWord from '../Keywords';
import './styles.css';
import { DAV_APIS } from '../../../adapters';

const getPreviewDisplay = (values: any, slug: string) => {
  const description =
    values.description ||
    'Please provide a meta description by editing the snippet below. If you don’t, Google will try to find a relevant part of your post to show in the search results.';
  const title = values.title || 'Please provide a meta title by editing the snippet below';
  const image = values.image || '';
  const pageSlug = slug || 'page-slug';

  if (values.resultType === 'mobile') {
    return (
      <div className='seo-mobile-preview'>
        <div className='seo-mobile-preview__url'>
          <div className='seo-mobile-preview__url-inner'>
            <img src='/dav-safaris-logo.png' alt='Dav Safaris' />
            <span>davsafaris.com › {pageSlug}</span>
          </div>
        </div>
        <div className='seo-mobile-preview__title'>
          <div className='seo-mobile-preview__title-inner line-clamp-2'>{title}</div>
        </div>
        <div className='seo-mobile-preview__description'>
          <div
            className='seo-mobile-preview__description-content line-clamp-4'
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          {image && (
            <div className='seo-mobile-preview__description-image'>
              <img src={image} alt='Dav Safaris' />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='w-full'>
      <div className='seo-mobile-preview__url'>
        <div className='seo-mobile-preview__url-inner'>
          <img src='/dav-safaris-logo.png' alt='Dav Safaris' />
          <span>davsafaris.com › {pageSlug}</span>
        </div>
      </div>
      <div className='seo-mobile-preview__title'>
        <div className='seo-mobile-preview__title-inner line-clamp-2'>{title}</div>
      </div>
      <div className='seo-mobile-preview__description'>
        <div
          className='seo-mobile-preview__description-content line-clamp-3'
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {image && (
          <div className='seo-mobile-preview__description-image desktop-view'>
            <img src={image} alt='Dav Safaris' />
          </div>
        )}
      </div>
    </div>
  );
};

interface initialDataProps {
  slug?: string;
  title?: string;
  description?: string;
  image?: string;
  key_words?: string[];
}

interface SEOProps {
  isEdit?: boolean;
  initialData?: initialDataProps;
  pageType: string;
  isModal?: boolean;
  setRefresh?: any;
  setShowModal?: any;
}

const SEO = (props: SEOProps) => {
  const { isEdit, initialData, pageType, isModal, setRefresh, setShowModal } = props;
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isModal);
  const [isNew, setIsNew] = useState(true);

  const getSeoData = async () => {
    if (!initialData?.slug) return;
    try {
      setLoading(true);
      const res = await DAV_APIS.getSearchEngineData(initialData?.slug as string);
      if (!res.data.seo) {
        setIsNew(true);
        setLoading(false);
        return;
      }
      setData(res.data?.seo);
      setIsNew(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Failed to fetch SEO data, Please try again');
    }
  };

  useEffect(() => {
    if (isEdit) {
      getSeoData();
    }
  }, [initialData?.slug, isEdit]);

  const getRoute = (slug: string) => {
    if (slug === 'home') return '/';
    if (slug === 'languages') return '/safari-updates/languages';
    return `/${slug}`;
  };

  const seoSubmitHandler = async (values: any) => {
    try {
      setLoading(true);
      const newData = {
        title: values.title,
        description: values.description,
        SEOImage: values.image,
        keywords: values.key_words,
        slug: isEdit ? initialData?.slug : values.title.toLowerCase().replace(/\s/g, '-'),
        pageType,
      };
      if (!isNew && data?.id) {
        const updated = await DAV_APIS.updateSearchEngineData(newData, data?.id);
        setData(updated.data.seo);
        if (pageType === 'page') {
          const route = getRoute(initialData?.slug as string);
          await DAV_APIS.revalidateSearchEngineRoute(route);
        }
        if (setRefresh) {
          setRefresh(Math.random());
        }
        if (isModal && setShowModal) {
          setShowModal(false);
        }
        setLoading(false);
        toast.success('SEO data updated successfully');

        return;
      }
      await DAV_APIS.createSearchEngineData(newData);
      setLoading(false);
      toast.success('SEO data submitted successfully');
    } catch (error) {
      setLoading(false);
      toast.error('Failed to update SEO, Please try again');
    }
  };

  const isDisabled = !isEdit && pageType !== 'page';

  const getButtonTitle = (dirty: boolean, isValid: boolean) => {
    if (loading) return 'Please wait...';
    if (!dirty) return 'No changes made';
    if (!isValid) return 'Please fill all required fields';
    return 'Submit';
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: data?.title || initialData?.title || '',
        description: data?.description || initialData?.description || '',
        image: data?.SEOImage || initialData?.image || '',
        key_words: data?.keywords || initialData?.key_words || [],
        resultType: 'mobile',
      }}
      validationSchema={seoSchema}
      onSubmit={async (values) => {
        seoSubmitHandler(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values, dirty, isValid }) => (
        <form onSubmit={handleSubmit}>
          <div className='bg-white shadow-sm rounded-md my-5'>
            <div
              className='w-full flex justify-between gap-2 items-center p-3 cursor-pointer'
              onClick={() => setOpen(!open)}
            >
              <h2 className='text-xl font-semibold'>SEO</h2>
              <div className='flex items-center gap-2'>
                {isEdit && (
                  <p className='mb-0 text-sm text-gray-300'>
                    Last Modified:{' '}
                    {moment(data.updatedAt || data.createdAt).format('Do MMM YYYY HH:mm A')}
                  </p>
                )}
                <button className='bg-transparent flex gap-1 items-center' type='button'>
                  {open ? (
                    <MdKeyboardArrowUp className='text-2xl' />
                  ) : (
                    <MdKeyboardArrowDown className='text-2xl' />
                  )}
                </button>
              </div>
            </div>
            <div
              className={`relative ${
                open ?
                  'p-3 visible h-auto overflow-visible  rounded-b-md'
                  : 'invisible h-0 overflow-hidden'
              } transition-all duration-200`}
            >
              {isDisabled && (
                <div className='absolute top-0 left-0 w-full h-full bg-black/40 z-10 flex justify-center items-center text-white'>
                  <p className='w-3/4 text-center'>
                    Please submit the {pageType} to automatically create the seo data, You can edit
                    this data via the {pageType} edit section
                  </p>
                </div>
              )}
              <div className='mx-2 mb-3 text-black border border-solid border-[#eee] p-2 rounded-md max-w-full md:max-w-[600px]'>
                <h6 className='text-black/70 cursor-pointer'>Google Preview: </h6>
                <div className='flex gap-2 items-center py-2'>
                  <div className='flex items-center gap-1'>
                    <Field
                      id='mobile'
                      value='mobile'
                      name='resultType'
                      type='radio'
                      className='w-4 h-4'
                    />
                    <label htmlFor='mobile' className='text-sm cursor-pointer'>
                      Mobile result
                    </label>
                  </div>
                  <div className='flex items-center gap-1'>
                    <Field
                      id='desktop'
                      value='desktop'
                      name='resultType'
                      type='radio'
                      className='w-4 h-4'
                    />
                    <label htmlFor='desktop' className='text-sm cursor-pointer'>
                      Desktop result{' '}
                    </label>
                  </div>
                </div>
                <div className='w-full p-3'>
                  {getPreviewDisplay(
                    values,
                    isEdit ? initialData?.slug : values.title.toLowerCase().replace(/\s/g, '-'),
                  )}
                </div>
              </div>
              <div className='mx-2 border border-solid border-[#eee] p-2 rounded-md bg-[#f5f5f5]'>
                <div className='w-full'>
                  <Input
                    type='text'
                    name='title'
                    label='Meta Title'
                    customClass='bg-white'
                    placeholder='Type meta title here'
                    disabled={isDisabled}
                    note={`${values.title.length}/200`}
                    isError={values.title.length > 200}
                  />
                  <Input
                    type='textarea'
                    rows={4}
                    name='description'
                    label='Meta Description'
                    customClass='bg-white'
                    note={`${values.description.length}/300`}
                    placeholder='Modify the your meta description here'
                    disabled={isDisabled}
                    isError={values.description.length > 300}
                  />
                  <Input
                    type='text'
                    name='image'
                    label='Image'
                    customClass='bg-white'
                    placeholder='Paste the link of the image you want'
                    disabled={isDisabled}
                  />
                  <NewKeyWord
                    key_words={values.key_words}
                    setKeys={(newWords: string[]) => setFieldValue('key_words', newWords)}
                  />
                </div>
              </div>
              <div className='flex justify-end p-5'>
                <button
                  type='submit'
                  className='flex justify-center items-center bg-tertiary hover:bg-tertiary/80 text-white w-auto px-4 py-1 rounded-md disabled:bg-tertiary/50'
                  disabled={loading || !dirty || !isValid}
                  title={getButtonTitle(dirty, isValid)}
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SEO;
