import { Formik } from 'formik';
import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { tourSchema } from '../../../../../constants';
import TourForm from '../sections/TourForm';
import { DAV_APIS } from '../../../../../adapters';
import { Alert, SEO } from '../../../../commons';

const NewTourPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const newTourSubmitHandler = async (values: any) => {
    setLoading(true);
    try {
      const tourActivities: string[] = [];
      values.tourActivities.split('\n').map((item: string) => tourActivities.push(item));

      const priceIncludes: string[] = [];
      values.priceIncludes.split('\n').map((item: string) => priceIncludes.push(item));

      const priceExcludes: string[] = [];
      values.priceExcludes.split('\n').map((item: string) => priceExcludes.push(item));

      const packageDetails = {
        price_inludes: priceIncludes,
        price_excludes: priceExcludes,
      };

      const data = {
        name: values.title,
        description: values.description,
        tourActivities: JSON.stringify(tourActivities),
        file: values.imageFile,
        country: values.country.toLowerCase(),
        category: values.category,
        duration: values.days,
        price: values.price,
        dayActivityDescription: JSON.stringify(values.itineraries),
        packageDetails: JSON.stringify(packageDetails),
      };
      const res = await DAV_APIS.tour.createNewTour(data);
      setLoading(false);
      if (res.status === 201) {
        Alert({ variant: 'success', message: 'Tour package created successfully' });
        navigate('/dashboard/tours');
      }
    } catch (error) {
      setLoading(false);
      Alert({ variant: 'error', message: 'Failed to create package, Please try again' });
    }
  };

  return (
    <div className='w-full py-2 md:p-5'>
      <button
        onClick={() => navigate('/dashboard/tours')}
        type='button'
        className='bg-transparent flex gap-1 items-center'
      >
        <MdArrowBackIosNew /> Back
      </button>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          description: '',
          imageCover: '',
          imageFile: '',
          tourActivities: '',
          country: '',
          category: '',
          days: '',
          price: '',
          priceIncludes: '',
          priceExcludes: '',
          itineraries: [],
          day: '',
          itinaryTitle: '',
          itinaryDescription: '',
          meal_plan: '',
          accommodation_plan: '',
        }}
        validationSchema={tourSchema}
        onSubmit={async (values, { resetForm }) => {
          await newTourSubmitHandler(values);

          resetForm();
        }}
      >
        {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
          <>
            <div className='bg-white rounded-md p-5 my-5'>
              <h2 className='text-xl font-semibold mb-2 text-center'>Add New Tour</h2>
              <div>
                <form onSubmit={handleSubmit}>
                  <TourForm values={values} setFieldValue={setFieldValue} />
                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {loading ? 'Creating...' : 'Create Tour'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <SEO
              pageType='tour'
              initialData={{
                title: values.title,
                description: values.description,
                image: values.imageCover,
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default NewTourPage;
