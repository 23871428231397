import { FC } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { PostBlockProps } from '../../../../../../constants';

interface PostBlockCardProps extends PostBlockProps {
  onEditHandler: () => void;
  onDeleteHandler: () => void;
  idx: number;
}

const PostBlockCard: FC<PostBlockCardProps> = ({
  title,
  description,
  onDeleteHandler,
  onEditHandler,
  idx,
}) => (
  <div className='itinary-card'>
    <div className='flex justify-between items-center gap-3'>
      <div className='itinary-card-number'>{idx + 1}</div>
      <div className='content'>
        <span>{title}</span>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>

    <div className='flex justify-between items-center '>
      <div
        className='hover:bg-black/5 flex justify-center items-center cursor-pointer  rounded-full h-10 w-10 -mr-2'
        onClick={onEditHandler}
      >
        <BiEditAlt className='text-xl text-primary cursor-pointer' title='Edit' />
      </div>
      <div
        className='hover:bg-black/5 flex justify-center items-center cursor-pointer  rounded-full h-10 w-10'
        onClick={onDeleteHandler}
      >
        <BsTrash className='text-xl text-red-500 cursor-pointer' title='Delete' />
      </div>
    </div>
  </div>
);

export default PostBlockCard;
