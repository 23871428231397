import { FC, useState } from 'react';
import { Input } from '../../commons';

const RegisterForm: FC = () => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const handlePasswordVisibility = (name: string) => {
    if (name === 'password' || name === 'confirmPassword') {
      setShowPassword((prevState) => ({
        ...prevState,
        [name]: !showPassword[`${name}`],
      }));
    }
  };
  return (
    <div className=''>
      <Input type='text' name='fname' label='First Name' />
      <Input type='text' name='lname' label='Last Name' />
      <Input type='email' name='email' label='Email' />

      <Input
        showEye
        type={showPassword.password ? 'text' : 'password'}
        name='password'
        label='Password'
        autoComplete='current-password'
        handlePasswordVisibility={handlePasswordVisibility}
      />
      <Input
        showEye
        type={showPassword.confirmPassword ? 'text' : 'password'}
        name='confirmPassword'
        label='Confirm Password'
        autoComplete='current-password'
        handlePasswordVisibility={handlePasswordVisibility}
      />
    </div>
  );
};

export default RegisterForm;
