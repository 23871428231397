import axios from 'axios';
import { agentsRequests } from './agentsRequests';
import { authRequests } from './authRequests';
import { countryRequests } from './countryRequests';
import { postRequests } from './postRequests';
import { tourRequests } from './tourRequests';
import { DAV_MAIN_DOMAIN } from '../../constants';

/**
 * Handle all the API calls to dav backend
 * @name requestAdapter
 * @param {(config: RequestConfig)=> any} requestor
 */
const requestAdapter = (requestor: any) => ({
  auth: authRequests(requestor),
  tour: tourRequests(requestor),
  country: countryRequests(requestor),
  post: postRequests(requestor),
  agents: agentsRequests(requestor),

  getSearchEngineData: async (slug: string) => {
    const url = `/SEO/get/${slug}`;
    const response = await requestor({
      method: 'GET',
      url,
    });
    return response;
  },

  getSearchEngineDataByPageType: async (pageType: string) => {
    const url = `/SEO/getByType/${pageType}`;
    const response = await requestor({
      method: 'GET',
      url,
    });
    return response;
  },

  // SEO
  createSearchEngineData: async (data: any) => {
    const url = `/SEO/create`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  updateSearchEngineData: async (data: any, seoId: string) => {
    const url = `/SEO/update/${seoId}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  revalidateSearchEngineRoute: async (slug: string) => {
    const url = `${DAV_MAIN_DOMAIN}/api/revalidate?path=${slug}&secret=4fDavsafarisToken6h`;
    const response = await axios.get(url);
    return response;
  },
});

export default requestAdapter;
