/**
 * @name tourRequests
 * @description Function used to make request to the tour endpoints
 * @param requestor
 * @returns {object} tourRequests
 */
export const tourRequests = (requestor: any) => ({
  /**
   * @name createNewTour
   * @description Function used to make request to create a new tour
   * @param {object} data
   * @returns {Promise<any>} response
   */
  createNewTour: async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('category', data.category);
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('tourActivities', data.tourActivities);
    formData.append('packageDetails', data.packageDetails);
    formData.append('country', data.country);
    formData.append('price', data.price);
    formData.append('duration', data.duration);
    formData.append('dayActivityDescription', data.dayActivityDescription);

    const url = `/tours/create`;
    const response = await requestor({
      method: 'POST',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name editTour
   * @description Function used to make request to edit a tour
   * @param {object} data
   * @param {string} tourId
   * @returns {object} response
   */
  editTour: async (data: any, tourId: string) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('category', data.category);
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('tourActivities', data.tourActivities);
    formData.append('packageDetails', data.packageDetails);
    formData.append('country', data.country);
    formData.append('price', data.price);
    formData.append('duration', data.duration);
    formData.append('dayActivityDescription', data.dayActivityDescription);

    const url = `/tours/updateTour/${tourId}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name deleteTourById
   * @description Function used to make request to delete a tour
   * @param {string} tourId
   * @returns {object} response
   */
  deleteTourById: async (tourId: string) => {
    const url = `/tours/deleteTour/${tourId}`;
    const response = await requestor({
      method: 'DELETE',
      url,
    });
    return response;
  },

  /**
   * @name deleteReviewById
   * @description Function used to make request to delete a tour
   * @param {string} reviewId
   * @returns {object} response
   */
  deleteReviewById: async (reviewId: string) => {
    const url = `/reviews/deleteReview/${reviewId}`;
    const response = await requestor({
      method: 'DELETE',
      url,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for estimate
   */
  get: {
    /**
     * @name getAllBookings
     * @description Function used to get all bookings
     * @returns {object} response
     */
    getAllBookings: async () => {
      const url = `/bookings/getAllBookings`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },
    /**
     * @name getTours
     * @description Function used to get all bookings
     * @returns {object} response
     */
    getTours: async () => {
      const url = `/tours/getAllTours`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getAllToursByCountry
     * @description Function used to get all tours by country name
     * @param {string} country
     * @returns {object} response
     */
    getAllToursByCountry: async (country: string) => {
      const url = `/tours/getAllTours/${country}`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getTourById
     * @description Function used to get tour by id
     * @param {string} tourId
     * @returns {Promise<any>} response
     */
    getTourById: async (tourId: string) => {
      const url = `/tours/${tourId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getReviews
     * @description Function used to get all reviews
     * @returns {object} response
     */
    getReviews: async () => {
      const url = `/reviews/getAllReviews`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },
  },
});
