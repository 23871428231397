import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { CountryProps } from '../constants';

let countriesData: CountryProps[] = [];

const useCountries = (refresh?: number | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<CountryProps[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      if (!navigator.onLine) return;
      if (countriesData.length > 0 && !refresh) {
        setCountries(countriesData);
        return;
      }
      setLoading(true);
      try {
        const res = await DAV_APIS.country.get.getCountries();
        setCountries(res.data.countries);
        countriesData = res.data.countries;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCountries();
  }, [refresh]);

  return {
    loading,
    countries,
  };
};

export default useCountries;
