import { ErrorMessage } from 'formik';
import React, { FC } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      {
        color: ['#5bc787', '#588048', '#f15d30', '#b3d4fc', 'red', 'blue', 'yellow'],
      },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
];

interface CustomEditorProps {
  onChange: (value: any) => void;
  value: any;
  placeholder?: string;
  customClass?: string;
  name?: string;
}

const CustomEditor: FC<CustomEditorProps> = ({
  onChange,
  value,
  placeholder,
  customClass,
  name,
}) => (
  <div className={`my-2 ${customClass ?? ''}`}>
    <ReactQuill
      theme='snow'
      modules={modules}
      formats={formats}
      value={value}
      onChange={onChange}
      defaultValue={value}
      placeholder={placeholder ?? 'Write your description here...'}
    />
    {name && (
      <span className='text-errorText'>
        <ErrorMessage name={name} />
      </span>
    )}
  </div>
);

export default CustomEditor;
