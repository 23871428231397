import React from 'react';
import moment from 'moment';
import { Rating } from 'react-custom-rating-component';
import { DAV_MAIN_DOMAIN } from '../../../constants';

const DATE_FORMAT = 'DD/MM/YYYY, hh:mm A';

export const bookingsColumns = [
  {
    Header: 'Name',
    accessor: 'user_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ cell: { value } }) => (
      <a href={`mailto:${value}`} className='hover:text-primary'>
        {value}
      </a>
    ),
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Country',
    accessor: 'country_of_residence',
    Cell: ({ cell: { value } }) => <div className='capitalize'>{value}</div>,
  },
  {
    Header: 'Travel Plans',
    accessor: 'travel_plans',
    style: {
      minWidth: '240px',
    },
    Cell: ({ cell: { value } }) => <div className='whitespace-pre-wrap'>{value}</div>,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
  },
];

export const reviewsColumns = [
  {
    Header: 'Name',
    accessor: 'user_name',
  },
  {
    Header: 'Rating',
    accessor: 'rating',
    Cell: ({ cell: { value } }) => (
      <div className='flex justify-center'>
        <Rating defaultValue={value} readOnly />
      </div>
    ),
  },
  {
    Header: 'Review',
    accessor: 'review',
    Cell: ({ cell: { value } }) => <div className='whitespace-pre-wrap'>{value}</div>,
  },
  {
    Header: 'Visit Month',
    accessor: 'visit_month',
  },
  {
    Header: 'Visit Year',
    accessor: 'visit_year',
  },
  {
    Header: 'Country',
    accessor: 'country_of_residence',
    Cell: ({ cell: { value } }) => <div className='capitalize'>{value}</div>,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
  },
];

export const subscribersColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ cell: { value } }) => (
      <a href={`mailto:${value}`} className='hover:text-primary'>
        {value}
      </a>
    ),
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },

  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
  },
];

export const pagesColumns = [
  {
    Header: 'Image',
    accessor: 'SEOImage',
    Cell: ({ cell: { value } }) => (
      <div className='flex justify-center bg-primary rounded-md'>
        <img src={value} alt='' className='w-full h-full object-cover rounded-md' />
      </div>
    ),
  },
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ cell: { value, row } }) => (
      <div className='whitespace-pre-wrap '>
        <div className='line-clamp-2 mb-1'>{value}</div>
        <span className='text-primary text-xs'>{`${DAV_MAIN_DOMAIN}/${row?.original?.slug}`}</span>
      </div>
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ cell: { value } }) => <div className='capitalize line-clamp-3'>{value}</div>,
  },
  {
    Header: 'Keywords',
    accessor: 'keywords',
    Cell: ({ cell: { value } }) => (
      <div className='capitalize line-clamp-2'>
        {value?.length < 1 ? 'N/A' : value?.map((word) => word).join(', ')}
      </div>
    ),
  },
  {
    Header: 'Last Modified',
    accessor: 'updatedAt',
    Cell: ({ cell: { value } }) => moment(value).format('Do MMM YYYY HH:mm A'),
  },
];
