import { useState } from 'react';
import { IoMdRefresh } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { SearchInput } from '../../../commons';
import { useCountries, useCountryTours, useSearch } from '../../../../hooks';
import DashboardLoader from '../../sections/Loader/DashboardLoader';
import TourCard from './TourCard';

const CountryTours = () => {
  const [refresh, setRefresh] = useState<number | null>(null);

  const { countrySlug } = useParams();
  const { countries, loading: countryLoading } = useCountries();
  const selectedCountry = countries.find((country) => country.slug === countrySlug)?.name as string;
  const { tours, loading } = useCountryTours(selectedCountry, refresh);
  const [searchTerm, setSearchTerm] = useState('' as string);

  const searchHandler = (e: any) => {
    const delayInputTimeoutId = setTimeout(() => {
      setSearchTerm(e.target.value);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  };

  const toursResults = useSearch(tours, searchTerm);

  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Tour Packages in {selectedCountry}</h1>
      <div className='flex gap-3 justify-around md:justify-end my-8'>
        <div>
          <SearchInput onChangeHandler={searchHandler} />
        </div>
        {/* <div>
                    <select
                        onChange={(e) => {
                            setSelectedCountry(e.target.value);
                        }}
                        value={selectedCountry}
                        className='text-primary rounded-md p-2 border border-[#cbd6e2] focus:outline-none focus:border-primary hover:border-primary leading-tight'
                    >
                        {countryLoading ? (
                            <option value=''>Loading...</option>
                        ) : (
                            countriesOptions.map((country) => (
                                <option key={country.value} value={country.value}>
                                    {country.label}
                                </option>
                            ))
                        )}
                    </select>
                </div> */}
        <div
          onClick={() => setRefresh(Math.random())}
          title='Refresh'
          className={`flex justify-center items-center bg-white rounded-full shadow-sm p-2 h-10 w-10 font-bold ${
            loading ?
              ' shadow-none hover:bg-[#eee] cursor-not-allowed hover:text-primary'
              : 'hover:bg-primary hover:text-white cursor-pointer'
          }`}
        >
          <IoMdRefresh className={`text-4xl ${loading ? 'refreshing_icon' : ''}`} />
        </div>
      </div>

      <div className='mt-5 w-full'>
        {loading || countryLoading ? (
          <div className='w-full my-4 flex justify-center items-center pr-20'>
            <DashboardLoader />
          </div>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 p-2 md:p-5 mb-3'>
            {toursResults.map((tour) => (
              <TourCard key={tour.id} tour={tour} />
            ))}
          </div>
        )}
        {!loading && tours.length === 0 && (
          <div className='w-full bg-white rounded-md flex justify-center items-center p-5'>
            <p>
              There are no tours found for <strong>{selectedCountry}</strong>
            </p>
          </div>
        )}
        {!loading && toursResults.length === 0 && searchTerm.length > 0 && (
          <div className='w-full bg-white rounded-md flex justify-center items-center p-5'>
            <p>
              There are no tours found for <strong>{searchTerm}</strong> in {selectedCountry}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryTours;
