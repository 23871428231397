import { Formik } from 'formik';
import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { countrySchema } from '../../../../../constants';
import { DAV_APIS } from '../../../../../adapters';
import { Alert, SEO } from '../../../../commons';
import CountryForm from '../CountryForm';
import { getCountryFlag } from '../../../../../utils';

const NewCountryPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const newCountrySubmitHandler = async (values: any) => {
    setLoading(true);
    try {
      const countryFlag = await getCountryFlag(values.name);
      const data = {
        name: values.name,
        title: values.title,
        description: values.description,
        countrySummary: values.countrySummary,
        countryImage: values.imageFile,
        flag: countryFlag,
      };
      const res = await DAV_APIS.country.createCountry(data);
      setLoading(false);
      if (res.status === 201) {
        Alert({ variant: 'success', message: 'Country created successfully' });
        navigate('/dashboard/countries');
      }
    } catch (error) {
      setLoading(false);
      Alert({ variant: 'error', message: 'Failed to create country, Please try again' });
    }
  };

  return (
    <div className='w-full py-2 md:p-5'>
      <div className='flex justify-between items-center'>
        <button
          onClick={() => navigate('/dashboard/countries')}
          type='button'
          className='bg-transparent flex gap-1 items-center'
        >
          <MdArrowBackIosNew /> Back
        </button>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          title: '',
          description: '',
          imageCover: '',
          imageFile: '',
          countrySummary: '',
        }}
        validationSchema={countrySchema}
        onSubmit={async (values, { resetForm }) => {
          await newCountrySubmitHandler(values);
          resetForm();
        }}
      >
        {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
          <>
            <div className='bg-white rounded-md p-5 my-5'>
              <h2 className='text-xl font-semibold mb-2 text-center'>Add New Country</h2>

              <div className=''>
                <form onSubmit={handleSubmit}>
                  <CountryForm values={values} setFieldValue={setFieldValue} />
                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {loading ? 'Creating...' : 'Create Country'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <SEO
              pageType='country'
              initialData={{
                title: values.name,
                description: values.description,
                image: values.imageCover,
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default NewCountryPage;
