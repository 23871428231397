import React, { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RiFileCopyLine } from 'react-icons/ri';
import { BsFlag, BsPersonHeart, BsSignpostSplit, BsSpeedometer2, BsStar } from 'react-icons/bs';
import { TbBrandBooking } from 'react-icons/tb';
import { AiOutlineTags } from 'react-icons/ai';
import { MdLogout, MdOutlineMarkEmailRead, MdOutlineTour } from 'react-icons/md';
import { logout } from '../../../store/slices/authSlice';
import { useCountries, useIsMobile } from '../../../hooks';
import { Loader, UserAvatar } from '../../commons';
import { DAV_ROLES } from '../../../constants';

interface DashboardItemProps {
  Icon: any;
  menuTitle: string;
  menuLink: string;
}

const DashboardItem: FC<DashboardItemProps> = ({ Icon, menuTitle, menuLink }) => (
  <NavLink
    to={`/dashboard/${menuLink}`}
    className={({ isActive }) =>
      `dav-dashboard__side-menu__body__item ${
        isActive ? 'dav-dashboard__side-menu__body__item--active' : ''
      }`
    }
  >
    <div className='dav-dashboard__side-menu__body__item__icon'>{Icon}</div>
    <div className='dav-dashboard__side-menu__body__item__text'>{menuTitle}</div>
  </NavLink>
);

interface SideBarProps {
  onClick: () => void;
}

const SideBar: FC<SideBarProps> = ({ onClick }) => {
  const user = useSelector((state: any) => state.auth.user);
  const { countries, loading: countryLoading } = useCountries();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <div className='dav-dashboard__side-menu-wrapper'>
      <div
        className={`dav-dashboard__side-menu__header ${
          isMobile ? 'dav-dashboard__side-menu__header--mobile' : ''
        }`}
      >
        {isMobile ? (
          <div className='dav-dashboard__header-user'>
            <div
              title={user?.username}
              className='dav-dashboard__header-user__avatar dav-dashboard__header-user__avatar--large'
            >
              <UserAvatar userName={user?.username} />
            </div>
            <div className='dav-dashboard__header-user__name'>{user?.username}</div>
          </div>
        ) : (
          <div className='dav-dashboard__side-menu__header-logo'>
            <img src='/assets/Dav-safaris-transparent-logo.png' alt='Dav Safaris' />
          </div>
        )}
      </div>
      <div className='dav-dashboard__side-menu__body'>
        <div className='w-full' onClick={onClick}>
          <DashboardItem Icon={<BsSpeedometer2 />} menuTitle='Dashboard' menuLink='overview' />
          {countryLoading && user.role === DAV_ROLES.AGENT ? (
            <div className='bg-primary rounded-r-full mr-3 opacity-50 flex justify-center items-center py-2'>
              {' '}
              <Loader isButton />{' '}
            </div>
          ) : null}
          {user.role === DAV_ROLES.AGENT ? (
            countries?.map((country: any) => (
              <DashboardItem
                key={country.id}
                Icon={<img src={country.flag} alt={country.name} className='w-8 h-auto' />}
                menuTitle={country.name}
                menuLink={`tours/${country.slug}`}
              />
            ))
          ) : (
            <>
              <DashboardItem Icon={<TbBrandBooking />} menuTitle='Bookings' menuLink='bookings' />
              <DashboardItem Icon={<MdOutlineTour />} menuTitle='Tours' menuLink='tours' />
              <DashboardItem
                Icon={<BsSignpostSplit />}
                menuTitle='Safari Updates'
                menuLink='safari-updates'
              />
              <DashboardItem Icon={<BsStar />} menuTitle='Client Reviews' menuLink='reviews' />
              <DashboardItem Icon={<BsFlag />} menuTitle='Countries' menuLink='countries' />
              <DashboardItem
                Icon={<AiOutlineTags />}
                menuTitle='Categories'
                menuLink='categories'
              />
              <DashboardItem Icon={<BsPersonHeart />} menuTitle='Agents' menuLink='agents' />
              <DashboardItem
                Icon={<MdOutlineMarkEmailRead />}
                menuTitle='Subscribers'
                menuLink='subscribers'
              />
              <DashboardItem Icon={<RiFileCopyLine />} menuTitle='Pages' menuLink='pages' />
            </>
          )}
        </div>

        <div className='flex justify-center pt-5'>
          <div
            onClick={handleLogout}
            className='flex justify-center items-center gap-3 bg-errorBg text-errorText rounded-md py-3 px-5 cursor-pointer hover:opacity-50'
          >
            <MdLogout />
            LogOut
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
