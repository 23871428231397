import { Formik } from 'formik';
import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useSearchQuery, useTour } from '../../../../../hooks';
import { tourSchema } from '../../../../../constants';
import TourForm from '../sections/TourForm';
import DashboardLoader from '../../../sections/Loader/DashboardLoader';
import { Alert, SEO } from '../../../../commons';
import { DAV_APIS } from '../../../../../adapters';

const NewTourPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const tourId = useSearchQuery('tour');
  const { tour, loading } = useTour(tourId as string);

  const editTourSubmitHandler = async (values: any) => {
    setIsLoading(true);
    try {
      const tourActivities: string[] = [];
      values.tourActivities.split('\n').map((item: string) => tourActivities.push(item));

      const priceIncludes: string[] = [];
      values.priceIncludes.split('\n').map((item: string) => priceIncludes.push(item));

      const priceExcludes: string[] = [];
      values.priceExcludes.split('\n').map((item: string) => priceExcludes.push(item));

      const packageDetails = {
        price_inludes: priceIncludes,
        price_excludes: priceExcludes,
      };

      const data = {
        name: values.title,
        description: values.description,
        tourActivities: JSON.stringify(tourActivities),
        file: values.imageFile,
        country: values.country.toLowerCase(),
        category: values.category,
        duration: values.days,
        price: values.price,
        dayActivityDescription: JSON.stringify(values.itineraries),
        packageDetails: JSON.stringify(packageDetails),
        key_words: JSON.stringify(values.key_words),
      };
      await DAV_APIS.tour.editTour(data, tourId as string);
      setIsLoading(false);
      Alert({ variant: 'success', message: 'Tour package edited successfully' });
      navigate('/dashboard/tours');
    } catch (error) {
      setIsLoading(false);
      Alert({ variant: 'error', message: 'Failed to edit package, Please try again' });
    }
  };

  let tourActivities: string = '';
  let priceIncludes: string = '';
  let priceExcludes: string = '';

  /* eslint-disable no-return-assign */
  tour?.tourActivities?.map((el) => el.length > 0 && (tourActivities += `${el}\n`));
  tour?.packageDetails?.price_inludes?.map((el) => el.length > 0 && (priceIncludes += `${el}\n`));
  tour?.packageDetails?.price_excludes?.map((el) => el.length > 0 && (priceExcludes += `${el}\n`));
  /* eslint-enable no-return-assign */

  return (
    <div className='w-full py-2 md:p-5'>
      <button
        onClick={() => navigate('/dashboard/tours')}
        type='button'
        className='bg-transparent flex gap-1 items-center'
      >
        <MdArrowBackIosNew /> Back
      </button>
      <div className='bg-white rounded-md p-5 my-5'>
        <h2 className='text-xl font-semibold mb-2 text-center'>Edit Tour Details</h2>
        {loading ? (
          <div className='w-full my-4 flex justify-center items-center pr-20'>
            <DashboardLoader />
          </div>
        ) : (
          <div className=''>
            <Formik
              enableReinitialize
              initialValues={{
                title: tour?.name || '',
                description: tour?.description || '',
                imageCover: tour?.imageCover || '',
                imageFile: '',
                tourActivities: tourActivities || '',
                category: tour?.category || '',
                country: tour?.country || '',
                days: tour?.duration || '',
                price: tour?.price || '',
                priceIncludes: priceIncludes || '',
                priceExcludes: priceExcludes || '',
                itineraries: tour?.dayActivityDescription || [],
                day: '',
                itinaryTitle: '',
                itinaryDescription: '',
                meal_plan: '',
                accommodation_plan: '',
                key_words: tour?.key_words || [],
              }}
              validationSchema={tourSchema}
              onSubmit={async (values, { resetForm }) => {
                await editTourSubmitHandler(values);

                resetForm();
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <TourForm values={values} setFieldValue={setFieldValue} edit />

                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {isLoading ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <SEO
        isEdit
        pageType='tour'
        initialData={{
          title: tour.name,
          description: tour.description,
          image: tour.imageCover,
          key_words: tour.key_words,
          slug: tour.slug,
        }}
      />
    </div>
  );
};

export default NewTourPage;
