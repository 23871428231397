import { FC, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import ItinaryCard from './ItinaryCard';
import { itinaryProps } from '../../../../../../constants';
import NewItinaryForm from './NewItinaryForm';
import { Modal } from '../../../../../commons';
import { clearNewItinaryForm, scrollToElement } from '../../../../../../utils';

interface ItinaryFormProps {
  values: any;
  setFieldValue: any;
  edit?: boolean;
}

const Itinary: FC<ItinaryFormProps> = ({ values, setFieldValue, edit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [showForm, setShowForm] = useState(edit ? !edit : true);
  const [showModal, setShowModal] = useState(false);

  const handleEditHandler = () => {
    if (selectedId !== null) {
      const itinary: itinaryProps = {
        day: values.day,
        title: values.itinaryTitle,
        description: values.itinaryDescription,
        meal_plan: values.meal_plan,
        accomodation: values.accommodation_plan,
      };
      const newItinaries = values.itineraries;
      newItinaries[selectedId] = itinary;
      setFieldValue('itineraries', newItinaries);
    }
    setIsEdit(false);
    clearNewItinaryForm(setFieldValue);
  };

  const handleAddItinary = () => {
    if (isEdit) {
      handleEditHandler();
      return;
    }
    setFieldValue('itineraries', [
      ...values.itineraries,
      {
        day: values.day,
        title: values.itinaryTitle,
        description: values.itinaryDescription,
        meal_plan: values.meal_plan,
        accomodation: values.accommodation_plan,
      },
    ]);
    clearNewItinaryForm(setFieldValue);
    if (values.itineraries.length === values.days - 1) {
      setShowForm(false);
    }
  };

  const onEditHandler = (id: number) => {
    setIsEdit(true);
    setShowForm(true);
    setSelectedId(id);
    const itinary = values.itineraries[id];
    setFieldValue('itinaryTitle', itinary.title);
    setFieldValue('itinaryDescription', itinary.description);
    setFieldValue('meal_plan', itinary.meal_plan);
    setFieldValue('accommodation_plan', itinary.accomodation);
    scrollToElement('edit');
  };

  const onDeleteHandler = (id: number) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleDeleteItinary = () => {
    if (selectedId) {
      const newItinaries = values.itineraries;
      newItinaries.splice(selectedId, 1);
      setFieldValue('itineraries', newItinaries);
      if (newItinaries.length - 1 < values.days - 1) {
        setShowForm(true);
      }
    }
    setShowModal(false);
  };

  return (
    <>
      <div className='bg-[#f5f5f5] rounded-md p-3 my-3' id='itinaries'>
        <div className='flex justify-between items-center py-2'>
          <h5 className='mb-2 italic text-black'>
            Itineraries{values.itineraries.length ? `(${values.itineraries.length})` : ''}
          </h5>
          <div
            onClick={() => setShowForm(!showForm)}
            title='Add Itinary'
            className='flex justify-center items-center bg-primary text-white rounded-full shadow-md p-2 h-10 w-10 cursor-pointer hover:bg-primary/80 font-bold'
          >
            <AiOutlinePlus className='text-4xl' />
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
          {values.itineraries.map((itinary: itinaryProps, idx: number) => (
            <div className='col-span-1' key={itinary.title}>
              <ItinaryCard
                {...itinary}
                onDeleteHandler={() => onDeleteHandler(idx)}
                onEditHandler={() => onEditHandler(idx)}
              />
            </div>
          ))}
        </div>
        <div id='edit'>
          {showForm && (
            <>
              <div className='flex justify-center py-5'>
                <hr className='w-4/5 text-center' />
              </div>
              <NewItinaryForm
                handleAddItinary={handleAddItinary}
                isEdit={isEdit}
                handleCancelEdit={() => {
                  setIsEdit(false);
                  clearNewItinaryForm(setFieldValue);
                }}
                values={values}
                setFieldValue={setFieldValue}
              />
            </>
          )}
        </div>
      </div>
      <Modal
        size='sm'
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setIsEdit(false);
        }}
        onSuccess={handleDeleteItinary}
        successText='Delete'
        loadingText='Saving...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Itinary</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete this Itinary? This action is permanent and can not be
            undone.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Itinary;
