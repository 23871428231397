import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { BookingProps } from '../constants';

let bookingsData: BookingProps[] = [];

const useBookings = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [bookings, setBookings] = useState<BookingProps[]>([]);

  useEffect(() => {
    const fetchBookings = async () => {
      if (!navigator.onLine) return;
      if (bookingsData.length > 0) {
        setBookings(bookingsData);
        return;
      }
      setLoading(true);
      try {
        const res = await DAV_APIS.tour.get.getAllBookings();
        setBookings(res.data.bookings);
        bookingsData = res.data.bookings;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  return {
    loading,
    bookings,
  };
};

export default useBookings;
