import { useNavigate } from 'react-router-dom';
import { TbBrandBooking } from 'react-icons/tb';
import { MdOutlineTour } from 'react-icons/md';
import { FaFlagUsa } from 'react-icons/fa';
import { BsPersonHeart, BsSignpostSplit } from 'react-icons/bs';
import Charts, { getCountriesData } from '../sections/Charts';
import { useCountries, useTours, useBookings, usePosts, useAgents } from '../../../hooks';

const Overview = () => {
  const { tours, loading } = useTours();
  const { bookings, loading: bookingLoading } = useBookings();
  const { countries, loading: countryLoading } = useCountries();
  const { posts, loading: postsLoading } = usePosts('posts');
  const { agents, loading: agentLoading } = useAgents();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <div className='w-full py-5'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 p-2 md:p-5 mb-3'>
        <div
          className='flex flex-col justify-between bg-white rounded-lg shadow-sm hover:shadow-md p-4 h-32 cursor-pointer'
          onClick={() => handleNavigation('/dashboard/bookings')}
        >
          <div className='flex items-center justify-between'>
            <h4 className='italic'>Bookings</h4>
            <TbBrandBooking className='text-3xl' />
          </div>
          <div className='flex justify-end font-bold text-2xl text-primary'>
            {bookingLoading ? '...' : bookings.length}
          </div>
        </div>

        <div
          onClick={() => handleNavigation('/dashboard/tours')}
          className='flex flex-col justify-between bg-white rounded-lg shadow-sm hover:shadow-md p-4 h-32 cursor-pointer'
        >
          <div className='flex items-center justify-between'>
            <h4 className='italic'>Packages</h4>
            <MdOutlineTour className='text-3xl' />
          </div>
          <div className='flex justify-end font-bold text-2xl text-primary'>
            {loading ? '...' : tours.length}
          </div>
        </div>
        <div
          onClick={() => handleNavigation('/dashboard/safari-updates')}
          className='flex flex-col justify-between bg-white rounded-lg shadow-sm hover:shadow-md p-4 h-32 cursor-pointer'
        >
          <div className='flex items-center justify-between'>
            <h4 className='italic'>Updates</h4>
            <BsSignpostSplit className='text-3xl' />
          </div>
          <div className='flex justify-end font-bold text-2xl text-primary'>
            {postsLoading ? '...' : posts.length}
          </div>
        </div>
        <div
          onClick={() => handleNavigation('/dashboard/countries')}
          className='flex flex-col justify-between bg-white rounded-lg shadow-sm hover:shadow-md p-4 h-32 cursor-pointer'
        >
          <div className='flex items-center justify-between'>
            <h4 className='italic'>Countries</h4>
            <FaFlagUsa className='text-3xl' />
          </div>
          <div className='flex justify-between font-bold text-2xl text-primary'>
            {countryLoading ? (
              '...'
            ) : (
              <>
                <div>
                  {loading ? '...' : getCountriesData(bookings)?.length}{' '}
                  <span className='text-tertiary text-sm italic'>Booked</span>
                </div>
                <div>{countryLoading ? '...' : countries.length}</div>
              </>
            )}
          </div>
        </div>
        <div
          onClick={() => handleNavigation('/dashboard/agents')}
          className='flex flex-col justify-between bg-white rounded-lg shadow-sm hover:shadow-md p-4 h-32 cursor-pointer'
        >
          <div className='flex items-center justify-between'>
            <h4 className='italic'>Agents</h4>
            <BsPersonHeart className='text-3xl' />
          </div>
          <div className='flex justify-end font-bold text-2xl text-primary'>
            {agentLoading ? '...' : agents.length}
          </div>
        </div>
      </div>
      <Charts data={bookings} />
    </div>
  );
};

export default Overview;
