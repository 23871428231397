import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { CountryProps } from '../constants';

const useCountry = (countryId: string) => {
  const [country, setCountry] = useState<CountryProps>({} as CountryProps);
  const [isLoading, setIsLoading] = useState(false);
  const fetchCountry = async () => {
    if (!countryId) return;
    setIsLoading(true);
    const res = await DAV_APIS.country.get.getCountryById(countryId);
    if (res.status === 200) {
      setCountry(res.data.country);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCountry();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);
  return { country, loading: isLoading };
};
export default useCountry;
