import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { CategoryProps } from '../constants';

const useCategory = (categoryId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState<CategoryProps>({} as CategoryProps);
  const fetchCategory = async () => {
    if (!categoryId) return;
    setIsLoading(true);
    const res = await DAV_APIS.country.get.getCategoryById(categoryId);
    if (res.status === 200) {
      setCategory(res.data.tourCategory);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  return { category, loading: isLoading };
};
export default useCategory;
