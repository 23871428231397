import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { IoMdRefresh } from 'react-icons/io';
import { BiEditAlt } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { useSearch, useTourCategories } from '../../../../hooks';
import { Alert, Modal, SearchInput } from '../../../commons';
import { CategoryProps, DAV_MAIN_DOMAIN } from '../../../../constants';
import { DAV_APIS } from '../../../../adapters';
import DashboardLoader from '../../sections/Loader/DashboardLoader';

const Categories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('' as string);
  const [selectedCategory, setSelectedCategory] = useState('' as string);
  const [refresh, setRefresh] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { categories, loading } = useTourCategories(refresh);
  const navigate = useNavigate();

  const searchHandler = (e: any) => {
    const delayInputTimeoutId = setTimeout(() => {
      setSearchTerm(e.target.value);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  };

  const categoriesResults = useSearch(categories, searchTerm);

  const handleDeleteCountry = async () => {
    setIsLoading(true);
    try {
      const res = await DAV_APIS.country.deleteCategoryById(selectedCategory as string);
      if (res.status === 204) {
        Alert({ message: 'Deleted Successfully', variant: 'success' });
      }
      setIsLoading(false);
      setShowModal(false);
      setRefresh(Math.random());
    } catch (error) {
      setIsLoading(false);
      setShowModal(false);
      Alert({ message: 'Failed to delete category', variant: 'error' });
    }
  };

  const onDeleteHandler = (countryId: string) => {
    setSelectedCategory(countryId);
    setShowModal(true);
  };

  const onEditHandler = (countryId: string) => {
    navigate(`/dashboard/categories/edit?category=${countryId}`);
  };

  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Manage Categories</h1>
      <div className='flex gap-3 justify-around md:justify-end my-8'>
        <div
          onClick={() => navigate('/dashboard/categories/new')}
          title='Add Category'
          className='flex justify-center items-center bg-white rounded-full shadow-md p-2 h-10 w-10 cursor-pointer hover:bg-primary font-bold hover:text-white'
        >
          <AiOutlinePlus className='text-4xl' />
        </div>
        <div>
          <SearchInput onChangeHandler={searchHandler} />
        </div>

        <div
          onClick={() => setRefresh(Math.random())}
          title='Refresh'
          className={`flex justify-center items-center bg-white rounded-full shadow-sm p-2 h-10 w-10 font-bold ${
            loading ?
              ' shadow-none hover:bg-[#eee] cursor-not-allowed hover:text-primary'
              : 'hover:bg-primary hover:text-white cursor-pointer'
          }`}
        >
          <IoMdRefresh className={`text-4xl ${loading ? 'refreshing_icon' : ''}`} />
        </div>
      </div>

      {loading ? (
        <DashboardLoader />
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 p-4'>
          {categoriesResults?.map((category: CategoryProps) => (
            <div className='grid-cols-1 country-card hover:shadow-md' key={category.id}>
              <div className='image bg-primary relative'>
                <img src={category.tourCategoryImage} alt={category.name} />
                <div className='country-name px-2 absolute top-0 right-0 bg-black/50 flex justify-center items-center'>
                  <h1 className='text-white text-2xl font-semibold'>{category.country.name}</h1>
                </div>
              </div>
              <div className='flex justify-between items-center py-2'>
                <h4 className='text-primary italic'>
                  <a
                    href={`${DAV_MAIN_DOMAIN}/${category.country.slug}/${category.slug}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {category.name}
                  </a>
                </h4>
                <div className='flex justify-between items-center '>
                  <div
                    className='hover:bg-black/5 flex justify-center items-center  rounded-full h-10 w-10 -mr-2'
                    onClick={() => onEditHandler(category.id)}
                  >
                    <BiEditAlt className='text-xl text-primary cursor-pointer' title='Edit' />
                  </div>
                  <div
                    className='hover:bg-black/5 flex justify-center items-center  rounded-full h-10 w-10'
                    onClick={() => onDeleteHandler(category.id)}
                  >
                    <BsTrash className='text-xl text-red-500 cursor-pointer' title='Delete' />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {searchTerm.length > 0 && categoriesResults?.length === 0 && (
        <div className='w-full bg-white p-5 rounded-md flex justify-center items-center'>
          No countries found for <span className='text-primary ml-1'>{searchTerm}</span>
        </div>
      )}
      <Modal
        size='sm'
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={handleDeleteCountry}
        loading={isLoading}
        successText='Delete'
        loadingText='Deleting...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Category</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete this category? This action is permanent and can not be
            undone.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Categories;
