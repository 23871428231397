import { FC } from 'react';

interface UserAvatarProps {
  userName: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ userName }) => {
  const image = `https://eu.ui-avatars.com/api/?name=${userName}&size=10`;
  return <img src={image} alt={userName} />;
};

export default UserAvatar;
