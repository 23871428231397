import React, { FC, useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { CustomEditor, Input } from '../../../../../commons';
import Button from '../../../../../commons/Button';

interface NewItinaryFormProps {
  handleAddItinary: () => void;
  isEdit?: boolean;
  handleCancelEdit?: () => void;
  values: any;
  setFieldValue: any;
}

const NewItinaryForm: FC<NewItinaryFormProps> = ({
  handleAddItinary,
  isEdit,
  handleCancelEdit,
  values,
  setFieldValue,
}) => {
  const [initialValue, setInitialValue] = useState(values.itinaryDescription);

  useEffect(() => {
    setFieldValue('itinaryDescription', initialValue);
  }, [initialValue]);

  useEffect(() => {
    setInitialValue(values.itinaryDescription);
  }, [values.itinaryDescription]);

  return (
    <div id='edit' className='p-3'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-3 items-end'>
        <div className='col-span-1'>
          <Input type='text' name='day' label='Day' customClass='bg-white' />
        </div>
        <div className='col-span-2'>
          <Input type='text' name='itinaryTitle' label='Itinary Title' customClass='bg-white' />
        </div>
      </div>
      <CustomEditor
        placeholder='Type itinary description here...'
        onChange={setInitialValue}
        value={initialValue}
        customClass='itinary-editor'
        name='itinaryDescription'
      />
      <div className='flex flex-col md:flex-row items-end gap-2'>
        <div className='w-full md:w-2/5'>
          <Input type='text' name='meal_plan' label='Meal Plan' customClass='bg-white' />
        </div>
        <div className='w-full md:w-2/5'>
          <Input
            type='text'
            name='accommodation_plan'
            label='Accommodation Plan'
            customClass='bg-white'
          />
        </div>

        <div className='flex items-center gap-3 w-full md:w-1/5'>
          {isEdit ? (
            <>
              <button
                onClick={handleCancelEdit}
                type='button'
                className='flex justify-center items-center bg-errorText text-white font-semibold hover:opacity-80 rounded-full py-2 px-3 w-10 h-10 shadow-sm hover:shadow-md'
              >
                <RxCross1 />
              </button>
              <button
                onClick={handleAddItinary}
                type='button'
                className='bg-primary text-white font-semibold hover:opacity-50 rounded-full py-2 px-3 w-full shadow-sm hover:shadow-md'
              >
                Save
              </button>
            </>
          ) : (
            <Button type='button' customClass='w-full' onClick={handleAddItinary}>
              {values.itineraries.length === values.days - 1 ? 'Finish' : 'Add'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewItinaryForm;
