import { useState, useEffect, useMemo } from 'react';
import { DAV_APIS } from '../adapters';
import { TourProps } from '../constants/interfaces';

let toursData: TourProps[] = [];

const useTours = (country?: string, refresh?: number) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tours, setTours] = useState<TourProps[]>([]);

  useEffect(() => {
    const fetchTours = async () => {
      if (!navigator.onLine) return;
      if (toursData.length > 0) {
        setTours(toursData);
        return;
      }
      setLoading(true);
      try {
        const res = await DAV_APIS.tour.get.getTours();
        setTours(res.data.tours);
        toursData = res.data.tours;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchTours();
  }, [country, refresh]);

  const filteredTours = useMemo(() => {
    if (!country) {
      return tours;
    }

    return tours.filter((tour) => tour.country.toLowerCase() === country.toLowerCase());
  }, [country, tours]);

  return {
    loading,
    tours: filteredTours,
  };
};

export default useTours;
