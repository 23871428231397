import { FC } from 'react';
import { Input } from '../../commons';

const ForgotPasswordForm: FC = () => (
  <div>
    <p className='text-center'>Enter the email used when creating an account on this platform</p>
    <Input type='email' name='email' />
  </div>
);

export default ForgotPasswordForm;
