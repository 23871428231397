import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import AuthCover from '../../../commons/AuthCover';
import { ForgotPasswordForm } from '../../authForms';
import { Alert, Loader } from '../../../commons';
import { forgotPasswordSchema } from '../../../../constants';
import { DAV_APIS } from '../../../../adapters';

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (values: any) => {
    setLoading(true);
    try {
      await DAV_APIS.auth.forgotPassword(values.email);
      setLoading(false);
      navigate('/login');
      Alert({ message: 'Reset Link Sent Successfully', variant: 'success' });
    } catch (error) {
      setLoading(false);
      Alert({ message: 'Failed to generate reset link', variant: 'error' });
    }
  };
  return (
    <AuthCover title='Forgot Password'>
      <div className='p-5'>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={async (values, { resetForm }) => {
            await submitHandler(values);

            resetForm();
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ForgotPasswordForm />

              <div className='flex justify-center gap-2 my-3 pt-8'>
                <button type='submit' className='dav-button dav-button-primary' disabled={loading}>
                  {loading ? <Loader isButton /> : 'Send Reset Link'}
                </button>
              </div>
            </form>
          )}
        </Formik>
        <div className='pt-3'>
          Don&apos;t have access to the email?{' '}
          <Link to='/register' className='underline hover:underline hover:text-secondary'>
            Create a new account
          </Link>
        </div>
      </div>
    </AuthCover>
  );
};

export default ForgotPassword;
