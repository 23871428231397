import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { Outlet } from 'react-router-dom';
import SideBar from './sections/SideBar';
import { useIsMobile } from '../../hooks';
import { UserAvatar } from '../commons';
import { CURRENT_VERSION } from '../../constants';

interface CloseIconProps {
  showSidebar: boolean;
  onClose: () => void;
}

const CloseIcon: FC<CloseIconProps> = ({ showSidebar, onClose }) => (
  <div
    className={`fixed ${
      showSidebar ? 'flex' : 'hidden'
    } justify-center items-center top-3 right-3 text-primary bg-white rounded-full w-14 h-14 shadow-md ease-in-out duration-300`}
    style={{
      zIndex: 100,
    }}
  >
    <svg
      onClick={onClose}
      className='z-40 flex items-center cursor-pointer'
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='40px'
      width='40px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill='none' stroke='currentColor' strokeWidth='2' d='M3,3 L21,21 M3,21 L21,3' />
    </svg>
  </div>
);

const Dashboard: FC = () => {
  const user = useSelector((state: any) => state.auth.user);
  const isMobile = useIsMobile();
  const [showSidebar, setShowSidebar] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);

  const handleCloseSideBar = () => {
    setShowSidebar(false);
    document.body.classList.remove('modal-open');
  };

  return (
    <div className='dav-dashboard text-tertiary  dav-dashboard-cover-wrapper'>
      <CloseIcon showSidebar={showSidebar} onClose={handleCloseSideBar} />
      {showSidebar && (
        <div
          className='fixed top-0 right-0 w-full h-full bg-black opacity-50'
          onClick={handleCloseSideBar}
          style={{
            zIndex: 70,
          }}
        />
      )}
      <div className='dav-dashboard__cover flex'>
        <div
          className={`dav-dashboard__side-menu ${
            !isMobile ? 'dav-dashboard__side-menu__large' : ''
          } ${showSidebar ? 'dav-dashboard__side-menu--active' : ''} ${
            hideMenu ? 'dav-dashboard__side-menu--hide' : ''
          }`}
        >
          <SideBar onClick={handleCloseSideBar} />
        </div>
        <div
          className={`dav-dashboard__main-content ${
            !isMobile && !hideMenu ? 'dav-dashboard__main-content__large' : ''
          } ${showSidebar ? 'dav-dashboard__main-content--active' : ''}`}
        >
          <div id='header' className='dav-dashboard__header shadow-md bg-white top-0 w-full'>
            {!isMobile && (
              <div className={`${hideMenu ? 'pl-0' : 'pl-[250px]'} ease-in-out duration-300`}>
                {hideMenu ? (
                  <AiOutlineMenuUnfold
                    className='text-tertiary text-2xl cursor-pointer'
                    onClick={() => setHideMenu(!hideMenu)}
                  />
                ) : (
                  <AiOutlineMenuFold
                    className='text-tertiary text-2xl cursor-pointer'
                    onClick={() => setHideMenu(!hideMenu)}
                  />
                )}
              </div>
            )}
            {isMobile && (
              <div className='dav-dashboard__header-logo'>
                <img src='/assets/Dav-safaris-transparent-logo.png' alt='Dav Safaris' />
              </div>
            )}
            {isMobile ? (
              <div className='dav-dashboard__header-menu-trigger'>
                <svg
                  onClick={() => {
                    setShowSidebar(true);
                    setHideMenu(false);
                    document.body.classList.add('modal-open');
                  }}
                  className='z-40 flex items-center cursor-pointer'
                  fill='currentColor'
                  viewBox='0 0 100 80'
                  width='40'
                  height='40'
                >
                  <rect width='100' height='10' />
                  <rect y='30' width='100' height='10' />
                  <rect y='60' width='100' height='10' />
                </svg>
              </div>
            ) : (
              <div className='dav-dashboard__header-user'>
                <div className='dav-dashboard__header-user__avatar' title={user?.username}>
                  <UserAvatar userName={user?.username} />
                </div>
              </div>
            )}
          </div>
          <div className='p-2 pt-[90px] md:pt-[80px] lg:pt-[73px]'>
            <Outlet />

            <div className='flex justify-center text-sm my-4 text-primary'>v{CURRENT_VERSION}</div>
            <div className='text-center text-sm text-gray-400 p-2'>
              Designed, Developed and Maintained by{' '}
              <a
                href='https://kimeramoses.com'
                target='_blank'
                rel='noreferrer'
                className='text-primary/70'
              >
                MOXTECH DEVELOPERS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
