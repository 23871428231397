/**
 * @name postRequests
 * @description Function used to make request to the tour endpoints
 * @param requestor
 * @returns {object} postRequests
 */
export const postRequests = (requestor: any) => ({
  /**
   * @name createNewPost
   * @description Function used to make request to create a new tour
   * @param {object} data
   * @param {string} type
   * @returns {Promise<any>} response
   */
  createNewPost: async (data: any, type: 'post' | 'language') => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('post_content', data.post_content);
    formData.append('post_blocks', data.post_blocks);
    const url = `/${type === 'language' ? 'languagePost' : 'posts'}/create`;

    const response = await requestor({
      method: 'POST',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name editPostDetails
   * @description Function used to make request to edit a post
   * @param {object} data
   * @param {string} postId
   * @param {string} type
   * @returns {object} response
   */
  editPostDetails: async (data: any, postId: string, type: string) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('post_content', data.post_content);
    formData.append('post_blocks', data.post_blocks);

    const url = `/${type === 'language' ? 'languagePost' : 'posts'}/updatePost/${postId}`;

    const response = await requestor({
      method: 'PATCH',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name deletePostById
   * @description Function used to make request to delete a post by id
   * @param {string} postId
   * @returns {object} response
   */
  deletePostById: async (postId: string, type: string) => {
    const url = `/${type === 'language' ? 'languagePost' : 'posts'}/deletePost/${postId}`;
    const response = await requestor({
      method: 'DELETE',
      url,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for estimate
   */
  get: {
    /**
     * @name getPosts
     * @description Function used to get all posts
     * @param {string} type
     * @returns {object} response
     */
    getPosts: async (type: string) => {
      const url = `/${type === 'language' ? 'languagePost' : 'posts'}/getAllPosts`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getPostById
     * @description Function used to get post by id
     * @param {string} type
     * @param {string} postId
     * @returns {Promise<any} response
     */
    getPostById: async (postId: string, type: string) => {
      const url = `/${type === 'language' ? 'languagePost' : 'posts'}/${postId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },
  },
});
