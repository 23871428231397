import { Formik } from 'formik';
import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { postSchema } from '../../../../../constants';
import PostForm from '../sections/PostForm';
import { DAV_APIS } from '../../../../../adapters';
import { Alert, SEO } from '../../../../commons';
import { usePost, useSearchQuery } from '../../../../../hooks';
import DashboardLoader from '../../../sections/Loader/DashboardLoader';

const EditPostPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const postId = useSearchQuery('post') as string;
  const categoryId = useSearchQuery('category') as 'post' | 'language';
  const { post, loading } = usePost(postId, categoryId);

  const editPostSubmitHandler = async (values: any) => {
    setIsLoading(true);
    try {
      const data = {
        name: values.title,
        post_content: values.postContent,
        file: values.imageFile,
        post_blocks: JSON.stringify(values.postBlocks),
      };
      await DAV_APIS.post.editPostDetails(data, postId, categoryId);
      setIsLoading(false);
      Alert({ variant: 'success', message: 'Post package edited successfully' });
      navigate('/dashboard/safari-updates');
    } catch (error) {
      setIsLoading(false);
      Alert({ variant: 'error', message: 'Failed to edit post, Please try again' });
    }
  };

  return (
    <div className='w-full py-2 md:p-5'>
      <div className='flex justify-between items-center'>
        <button
          onClick={() => navigate('/dashboard/safari-updates')}
          type='button'
          className='bg-transparent flex gap-1 items-center'
        >
          <MdArrowBackIosNew /> Back
        </button>
      </div>
      <div className='bg-white rounded-md p-5 my-5'>
        <h2 className='text-xl font-semibold mb-2 text-center'>Edit Post</h2>
        {categoryId === 'language' && (
          <p className='text-sm text-center text-primary'>
            **Please use any language of your choice in any of the fields below**
          </p>
        )}

        {loading ? (
          <DashboardLoader />
        ) : (
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                title: post?.name || '',
                postContent: post?.post_content || '',
                imageCover: post?.postImage || '',
                imageFile: '',
                postBlocks: post?.post_blocks || [],
                blockTitle: '',
                blockDescription: '',
              }}
              validationSchema={postSchema}
              onSubmit={async (values, { resetForm }) => {
                await editPostSubmitHandler(values);

                resetForm();
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <PostForm values={values} setFieldValue={setFieldValue} edit />
                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {isLoading ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <SEO
        isEdit
        pageType='post'
        initialData={{
          title: post.name,
          description: post.post_content,
          image: post.postImage,
          key_words: post.key_words,
          slug: post.slug,
        }}
      />
    </div>
  );
};

export default EditPostPage;
