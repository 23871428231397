import React, { FC, useState } from 'react';
// @ts-ignore
import Chart from 'react-apexcharts';

export const getCountriesData = (bookings: any) => {
  if (!bookings) return null;
  const countriesMap = new Map<string, number>();

  bookings.forEach((booking: any) => {
    const country = booking.country_of_residence.toLowerCase();
    if (countriesMap.has(country)) {
      countriesMap.set(country, countriesMap.get(country)! + 1);
    } else {
      countriesMap.set(country, 1);
    }
  });

  const countries = Array.from(countriesMap).map(([country, bkg]) => ({
    country,
    bkg,
  }));

  return countries;
};

const getBookingsByMonth = (bookings: any, year: number) => {
  if (!bookings || year > new Date().getFullYear()) return null;

  const monthsMap = new Map<string, number>();

  const monthsShortNames = Array.from({ length: 12 }, (_, monthIndex) =>
    new Date(year, monthIndex, 1).toLocaleString('default', { month: 'short' }),
  );
  monthsShortNames.forEach((month) => {
    monthsMap.set(month, 0);
  });

  bookings.forEach((booking: any) => {
    const createdAt = new Date(booking.createdAt);
    if (createdAt.getFullYear() === year) {
      const month = createdAt.toLocaleString('default', { month: 'short' });

      if (monthsMap.has(month)) {
        monthsMap.set(month, monthsMap.get(month)! + 1);
      } else {
        monthsMap.set(month, 1);
      }
    }
  });

  return Array.from(monthsMap).map(([month, bkgs]) => ({
    month,
    bkgs,
  }));
};

const getYearsCovered = (bookings: any): number[] => {
  const yearsSet = new Set<number>();
  bookings.forEach((booking: any) => {
    const createdAt = new Date(booking.createdAt);
    yearsSet.add(createdAt.getFullYear());
  });
  return Array.from(yearsSet);
};

interface ChartsProps {
  data: any;
}
const Charts: FC<ChartsProps> = ({ data }) => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

  const countries = getCountriesData(data)!;

  const months = getBookingsByMonth(data, selectedYear)!;

  const options = {
    chart: {
      id: 'Bookings',
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    colors: ['#5bc787'],
  };
  const optionsCountries = {
    chart: {
      id: 'BookingsByCountries',
    },
    xaxis: {
      categories: countries.map((country: any) => country.country),
    },
    colors: ['#f15d30'],
  };

  const series = [
    {
      name: 'Bookings',
      data: months.map((month: any) => month.bkgs),
    },
  ];

  const seriesCountries = [
    {
      name: 'countries',
      data: countries.map((country: any) => country.bkg),
    },
  ];

  const yearsCovered = getYearsCovered(data);

  return (
    <div className='w-full'>
      <div className='flex flex-col lg:flex-row justify-around items-center gap-4 p-2 md:p-5'>
        <div className='bg-white shadow-sm rounded-md p-4 w-full lg:w-1/2'>
          <div className='flex justify-between items-center'>
            <div className='text-2xl font-bold'>Number of Bookings in {selectedYear}</div>
            <div className='w-[130px] md:w-auto'>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(+e.target.value)}
                className='border border-gray-300 rounded-md px-4 py-1 text-tertiary'
              >
                {yearsCovered.map((year) => (
                  <option value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>
          <Chart options={options} series={series} type='bar' />
        </div>
        <div className='bg-white shadow-sm rounded-md p-4 w-full lg:w-1/2'>
          <div className='text-2xl font-bold'>Bookings by Countries</div>
          <Chart options={optionsCountries} series={seriesCountries} type='line' />
        </div>
      </div>
    </div>
  );
};

export default Charts;
