import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import AuthCover from '../../../commons/AuthCover';
import { LoginForm } from '../../authForms';
import { Alert, Loader } from '../../../commons';
import { loginSchema } from '../../../../constants';
import { DAV_APIS } from '../../../../adapters';
import { authenticationSuccess } from '../../../../store/slices/authSlice';
import { SaveTokenInLocalStorage } from '../../../../store/actions';

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        email: values.email,
        password: values.password,
      };
      const res = await DAV_APIS.auth.login(data);
      if (res.status === 200) {
        dispatch(authenticationSuccess(res.data));
        SaveTokenInLocalStorage(dispatch, res.data);
      }
      setLoading(false);
      navigate('/dashboard/overview');
      Alert({ message: 'Login successful', variant: 'success' });
    } catch (error) {
      setLoading(false);
      Alert({ message: 'Login failed!', variant: 'error' });
    }
  };

  return (
    <AuthCover title='Login'>
      <div className='p-5'>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={loginSchema}
          onSubmit={async (values, { resetForm }) => {
            await handleLogin(values);
            resetForm();
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LoginForm />

              <div className='flex justify-center gap-2 my-3'>
                <button
                  type='submit'
                  className='dav-button dav-button-primary w-full'
                  disabled={loading}
                >
                  {loading ? <Loader isButton /> : 'Login In'}
                </button>
              </div>
            </form>
          )}
        </Formik>
        <div className='flex justify-center gap-2 pt-3'>
          Not a Dav Safaris member?{' '}
          <Link to='/register' className='underline hover:underline hover:text-secondary'>
            Register
          </Link>
        </div>
      </div>
    </AuthCover>
  );
};

export default Login;
