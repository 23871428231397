import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { AgentProps } from '../constants';

let agentsData: AgentProps[] = [];

const useAgents = (refresh?: number | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [agents, setAgents] = useState<AgentProps[]>([]);

  useEffect(() => {
    const fetchAgents = async () => {
      if (!navigator.onLine) return;
      if (agentsData.length > 0 && !refresh) {
        setAgents(agentsData);
        return;
      }
      setLoading(true);
      try {
        const res = await DAV_APIS.agents.get.getAgents();
        setAgents(res.data.agents);
        agentsData = res.data.agents;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [refresh]);

  return {
    loading,
    agents,
  };
};

export default useAgents;
