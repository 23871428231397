import React from 'react';
import { useSelector } from 'react-redux';
import { BsWhatsapp } from 'react-icons/bs';
import { BiPhoneCall } from 'react-icons/bi';
import { MdOutlineEmail } from 'react-icons/md';
import { Formik } from 'formik';
import { Alert, Input, Loader } from '../../../commons';
import { messageSchema } from '../../../../constants';
import './styles.css';
import { DAV_APIS } from '../../../../adapters';

const AgentOverview = () => {
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: any) => state.auth.user);
  const userName = user?.username
    ?.split(' ')
    .map((name: string) => name[0])
    .join('');

  const handleInquiry = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        name: values.name,
        email: values.email,
        contact: user.phoneNumber,
        message: values.message,
        country_of_residence: user.country,
      };
      await DAV_APIS.auth.contactUs(data);
      setLoading(false);
      Alert({
        message: 'Message sent to Dav Safari team, You will hear from  them shortly',
        variant: 'success',
      });
    } catch (error) {
      setLoading(false);
      Alert({
        message: 'Failed to send message, Please use the contacts to contact Dav safaris urgently',
        variant: 'error',
      });
    }
  };
  return (
    <div className='w-full p-3'>
      <h5>Dav Safaris Agent Dashboard</h5>
      <div className='border border-solid rounded-md p-2 welcome-screen'>
        <div className='section-wrapper'>
          <div className='section-title bg-[#7a7a7a2e] flex flex-wrap items-center gap-3 p-3 rounded-t-md'>
            <div className='user-image w-24 h-24 min-w-24 rounded-full bg-primary flex items-center justify-center'>
              <h5 className='text-white text-2xl uppercase'>{userName}</h5>
            </div>
            <h5>Hello {user?.username}, Welcome to your Dashboard</h5>
          </div>
          <div className='section-body p-3 bg-[#7a7a7a12]'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 p-2 md:p-5 mb-3'>
              <div className='p-2'>
                <p>
                  In this dashboard, you can view all available tour packages in the different
                  countries that we currently operate in with their respective prices and advise our
                  clients accordingly
                </p>
                <p>
                  If you need any ugent help from the Dav Safaris Team, Please use one of the
                  following media to reach us or use the form to mail us directly
                </p>
                <div className='flex flex-col gap-2 py-3 bg-white mt-5 p-3 rounded-md'>
                  <div className='flex gap-2 items-center'>
                    <BsWhatsapp className='text-lg' />{' '}
                    <a href='https://wa.me/+256701412430' className='text-primary'>
                      +256701412430
                    </a>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <BiPhoneCall className='text-xl' />{' '}
                    <a href='tel:+256757795781' className='text-primary'>
                      +256757795781
                    </a>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <MdOutlineEmail className='text-xl' />{' '}
                    <a href='mailto:info@davsafaris.com' className='text-primary'>
                      +256757795781
                    </a>
                  </div>
                </div>
              </div>
              <div className=''>
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: user?.email,
                    name: user?.username,
                  }}
                  validationSchema={messageSchema}
                  onSubmit={async (values, { resetForm }) => {
                    await handleInquiry(values);
                    resetForm();
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className='border border-solid p-2 rounded-md'>
                      <Input type='text' name='name' label='Name' customClass='bg-white' />
                      <Input type='text' name='email' label='Email' customClass='bg-white' />
                      <Input
                        type='textarea'
                        rows={4}
                        name='message'
                        label='Message'
                        customClass='bg-white'
                      />

                      <div className='flex justify-center gap-2 my-3 pt-3'>
                        <button
                          type='submit'
                          className='dav-button dav-button-primary'
                          disabled={loading}
                        >
                          {loading ? <Loader isButton /> : 'Send Message'}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentOverview;
