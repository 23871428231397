import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { SearchInput, Modal, Alert } from '../../../commons';
import { useCountries, useCountryTours, useSearch } from '../../../../hooks';
import TourCard from './TourCard';
import { DAV_APIS } from '../../../../adapters';
import DashboardLoader from '../../sections/Loader/DashboardLoader';

const Tours = () => {
  const [selectedCountry, setSelectedCountry] = useState('uganda');

  const [selectedTour, setSelectedTour] = useState('' as string | null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState<number | null>(null);
  const { countries, loading: countryLoading } = useCountries();
  const { tours, loading } = useCountryTours(selectedCountry, refresh);
  const [searchTerm, setSearchTerm] = useState('' as string);

  const navigate = useNavigate();

  const countriesOptions = countries.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  const handleDeleteTour = async () => {
    setIsLoading(true);
    try {
      const res = await DAV_APIS.tour.deleteTourById(selectedTour as string);
      if (res.status === 204) {
        Alert({ message: 'Deleted Successfully', variant: 'success' });
      }
      setIsLoading(false);
      setShowModal(false);
      setRefresh(Math.random());
    } catch (error) {
      setIsLoading(false);
      setShowModal(false);
      Alert({ message: 'Failed to delete tour', variant: 'error' });
    }
  };

  const searchHandler = (e: any) => {
    const delayInputTimeoutId = setTimeout(() => {
      setSearchTerm(e.target.value);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  };

  const toursResults = useSearch(tours, searchTerm);

  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Manage Tours</h1>
      <div className='flex gap-3 justify-around md:justify-end my-8'>
        <div
          onClick={() => navigate('/dashboard/tours/new')}
          title='Add Tour'
          className='flex justify-center items-center bg-white rounded-full shadow-md p-2 h-10 w-10 cursor-pointer hover:bg-primary font-bold hover:text-white'
        >
          <AiOutlinePlus className='text-4xl' />
        </div>
        <div>
          <SearchInput onChangeHandler={searchHandler} />
        </div>
        <div>
          <select
            onChange={(e) => {
              setSelectedCountry(e.target.value);
            }}
            value={selectedCountry}
            className='text-primary rounded-md p-2 border border-[#cbd6e2] focus:outline-none focus:border-primary hover:border-primary leading-tight'
          >
            {countryLoading ? (
              <option value=''>Loading...</option>
            ) : (
              countriesOptions.map((country) => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))
            )}
          </select>
        </div>
        <div
          onClick={() => setRefresh(Math.random())}
          title='Refresh'
          className={`flex justify-center items-center bg-white rounded-full shadow-sm p-2 h-10 w-10 font-bold ${
            loading ?
              ' shadow-none hover:bg-[#eee] cursor-not-allowed hover:text-primary'
              : 'hover:bg-primary hover:text-white cursor-pointer'
          }`}
        >
          <IoMdRefresh className={`text-4xl ${loading ? 'refreshing_icon' : ''}`} />
        </div>
      </div>

      <div className='mt-5 w-full'>
        {loading ? (
          <div className='w-full my-4 flex justify-center items-center pr-20'>
            <DashboardLoader />
          </div>
        ) : (
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 p-2 md:p-5 mb-3'>
            {toursResults.map((tour) => (
              <TourCard
                key={tour.id}
                tour={tour}
                onEditHandler={() => navigate(`/dashboard/tours/edit?tour=${tour.id}`)}
                onDeleteHandler={() => {
                  setShowModal(true);
                  setSelectedTour(tour.id);
                }}
              />
            ))}
          </div>
        )}
        {!loading && tours.length === 0 && (
          <div className='w-full bg-white rounded-md flex justify-center items-center p-5'>
            <p>
              There are no tours found for <strong>{selectedCountry}</strong>
            </p>
          </div>
        )}
        {!loading && toursResults.length === 0 && searchTerm.length > 0 && (
          <div className='w-full bg-white rounded-md flex justify-center items-center p-5'>
            <p>
              There are no tours found for <strong>{searchTerm}</strong> in {selectedCountry}
            </p>
          </div>
        )}
      </div>
      <Modal
        size='sm'
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={handleDeleteTour}
        loading={isLoading}
        successText='Delete'
        loadingText='Deleting...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Tour</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete this tour? This action is permanent and can not be
            undone.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Tours;
