import { useEffect, useState } from 'react';

const useSearch = (data: any[], searchTerm: string) => {
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const searchHandler = () => {
    if (searchTerm !== '') {
      const Results = data.filter((Result) =>
        Object.values(Result)
          .join(' ')
          .replaceAll('-', ' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );
      setSearchResults(Results);
    } else {
      setSearchResults(data);
    }
  };

  useEffect(() => {
    searchHandler();
  }, [data, searchTerm]);

  return searchResults;
};

export default useSearch;
