import React, { FC } from 'react';

interface SearchInputProps {
  customClass?: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const SearchInput: FC<SearchInputProps> = ({ customClass, onChangeHandler, placeholder }) => (
  <input
    type='search'
    placeholder={placeholder ?? 'Type to search'}
    className={`w-full appearance-none text-primary rounded-md border focus:outline-none focus:border-b-2 focus:border-solid focus:border-primary box-border border-[#cbd6e2] select-none py-2 px-3  leading-tight hover:border-primary ${customClass}`}
    onChange={onChangeHandler}
  />
);

export default SearchInput;
