import { FC, useState, useEffect } from 'react';
import { CustomEditor, ImageUpload, Input } from '../../../../commons';
import { useCountries } from '../../../../../hooks';
import Itinary from './itinaries';
import useCountryCategories from '../../../../../hooks/useCountryCategories';

interface NewTourFormProps {
  values: any;
  setFieldValue: any;
  edit?: boolean;
}
const TourForm: FC<NewTourFormProps> = ({ values, setFieldValue, edit }) => {
  const [initialValue, setInitialValue] = useState(values.description);
  const { countries, loading: countryLoading } = useCountries();
  const { categories, loading: categoryLoading } = useCountryCategories(values.country);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setFieldValue('imageFile', file);

    const image = URL.createObjectURL(file);
    setFieldValue('imageCover', image);
  };

  const filteredCategories = categories.filter(
    (category: any) => category?.country?.name?.toLowerCase() === values.country?.toLowerCase(),
  );

  const getCategoryOptions = () => {
    if (categoryLoading) return [{ label: 'Loading...', value: '' }];
    if (!values.country) return [{ label: 'Please select country first!', value: '' }];
    if (filteredCategories.length === 0)
      return [{ label: 'No categories found for this country!', value: '' }];

    return [
      { label: 'Select Category', value: '' },
      ...filteredCategories.map((category: any) => ({
        label: category.name,
        value: category.slug,
      })),
    ];
  };

  useEffect(() => {
    setFieldValue('description', initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (values.country && !edit) {
      setFieldValue('category', '');
    }
  }, [values.country]);

  return (
    <div>
      <Input type='text' name='title' label='Title' />
      <CustomEditor
        placeholder='Type tour description here...'
        onChange={setInitialValue}
        value={initialValue}
        name='description'
      />
      <div className='grid grid-cols-1 md:grid-cols-3 md:gap-3 items-end'>
        <div className='col-span-2'>
          <Input
            type='textarea'
            label='Tour Highlights'
            placeholder='Write each highlight on a new line'
            name='tourActivities'
            rows={4}
          />
        </div>
        <div className='col-span-1 mt-4 md:mt-0j'>
          <ImageUpload tourImage={values.imageCover} tourImageHandler={handleImageChange} />
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-4 gap-3 items-end'>
        <div className='col-span-1'>
          <Input
            type='select'
            name='country'
            label='Country'
            options={
              countryLoading ?
                [{ label: 'Loading...', value: '' }]
                : [
                    { label: 'Select Country', value: '' },
                    ...countries.map((country: any) => ({
                      label: country.name,
                      value: country.name.toLowerCase(),
                    })),
                  ]
            }
          />
        </div>
        <div className='col-span-1'>
          <Input type='select' name='category' label='Category' options={getCategoryOptions()} />
        </div>
        <div className='col-span-1'>
          <Input type='number' name='days' label='Number of Days' min={1} />
        </div>
        <div className='col-span-1'>
          <Input type='text' name='price' label='Price($)' />
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-end'>
        <div className='col-span-1'>
          <Input
            type='textarea'
            name='priceIncludes'
            label='Price Includes'
            placeholder='Write each item on a new line'
            rows={4}
          />
        </div>
        <div className='col-span-1'>
          <Input
            type='textarea'
            name='priceExcludes'
            label='Price Excludes'
            placeholder='Write each item on a new line'
            rows={4}
          />
        </div>
      </div>
      <Itinary setFieldValue={setFieldValue} values={values} edit={edit} />
    </div>
  );
};

export default TourForm;
