import { useEffect, useState } from 'react';
import { DAV_APIS } from '../adapters';
import { PostProps } from '../constants';

const usePost = (postId: string, type: 'post' | 'language') => {
  const [post, setPost] = useState<PostProps>({} as PostProps);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPost = async () => {
      if (!postId || !type) return;
      setLoading(true);
      const res = await DAV_APIS.post.get.getPostById(postId, type);
      if (res.status === 200) {
        setPost(res.data.post);
      }
      setLoading(false);
    };
    getPost();
  }, [postId, type]);

  return { post, loading };
};

export default usePost;
