import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { SearchInput, Modal, Alert } from '../../../commons';
import { usePosts, useSearch } from '../../../../hooks';
import { DAV_APIS } from '../../../../adapters';
import DashboardLoader from '../../sections/Loader/DashboardLoader';
import PostCard from './PostCard';

const Posts = () => {
  const [selectedCategory, setSelectedCategory] = useState<'posts' | 'language'>('posts');
  const [selectedPost, setSelectedPost] = useState('' as string | null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState<number | null>(null);
  const { posts, loading } = usePosts(selectedCategory, refresh);
  const [searchTerm, setSearchTerm] = useState('' as string);

  const navigate = useNavigate();
  const handleDeletePost = async () => {
    setIsLoading(true);
    try {
      const res = await DAV_APIS.post.deletePostById(selectedPost as string, selectedCategory);
      if (res.status === 204) {
        Alert({ message: 'Deleted Successfully', variant: 'success' });
      }
      setIsLoading(false);
      setShowModal(false);
      setRefresh(Math.random());
    } catch (error) {
      setIsLoading(false);
      setShowModal(false);
      Alert({ message: 'Failed to delete post', variant: 'error' });
    }
  };

  const searchHandler = (e: any) => {
    const delayInputTimeoutId = setTimeout(() => {
      setSearchTerm(e.target.value);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  };

  const postsResults = useSearch(posts, searchTerm);

  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Manage Safari Updates</h1>
      <div className='flex gap-3 justify-around md:justify-end my-8'>
        <div
          onClick={() => navigate('/dashboard/safari-updates/new')}
          title='Add New Post'
          className='flex justify-center items-center bg-white rounded-full shadow-md p-2 h-10 w-10 cursor-pointer hover:bg-primary font-bold hover:text-white'
        >
          <AiOutlinePlus className='text-4xl' />
        </div>
        <div>
          <SearchInput onChangeHandler={searchHandler} />
        </div>
        <div>
          <select
            onChange={(e) => {
              setSelectedCategory(e.target.value as 'posts' | 'language');
            }}
            value={selectedCategory}
            className='text-primary rounded-md p-2 border border-[#cbd6e2] focus:outline-none focus:border-primary hover:border-primary leading-tight'
          >
            <option value='posts'>Posts</option>
            <option value='language'>Language Posts</option>
          </select>
        </div>
        <div
          onClick={() => setRefresh(Math.random())}
          title='Refresh'
          className={`flex justify-center items-center bg-white rounded-full shadow-sm p-2 h-10 w-10 font-bold ${
            loading ?
              ' shadow-none hover:bg-[#eee] cursor-not-allowed hover:text-primary'
              : 'hover:bg-primary hover:text-white cursor-pointer'
          }`}
        >
          <IoMdRefresh className={`text-4xl ${loading ? 'refreshing_icon' : ''}`} />
        </div>
      </div>

      <div className='mt-5 w-full'>
        {loading ? (
          <div className='w-full my-4 flex justify-center items-center pr-20'>
            <DashboardLoader />
          </div>
        ) : (
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 p-2 md:p-5 mb-3'>
            {postsResults.map((post) => (
              <PostCard
                key={post.id}
                post={post}
                isLanguage={selectedCategory === 'language'}
                onEditHandler={() =>
                  navigate(
                    `/dashboard/safari-updates/edit?post=${post.id}&category=${selectedCategory}`,
                  )
                }
                onDeleteHandler={() => {
                  setShowModal(true);
                  setSelectedPost(post.id);
                }}
              />
            ))}
          </div>
        )}
        {!loading && posts.length === 0 && (
          <div className='w-full bg-white rounded-md flex justify-center items-center p-5'>
            <p>There are no posts found</p>
          </div>
        )}
        {!loading && postsResults.length === 0 && searchTerm.length > 0 && (
          <div className='w-full bg-white rounded-md flex justify-center items-center p-5'>
            <p>
              There are no posts found for <strong>{searchTerm}</strong>
            </p>
          </div>
        )}
      </div>
      <Modal
        size='sm'
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={handleDeletePost}
        loading={isLoading}
        successText='Delete'
        loadingText='Deleting...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Post</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete this post? This action is permanent and can not be
            undone.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Posts;
