import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import AuthCover from '../../../commons/AuthCover';
import { RegisterForm } from '../../authForms';
import { Alert, Loader } from '../../../commons';
import { registerSchema } from '../../../../constants';
import { DAV_APIS } from '../../../../adapters';

const Register: FC = () => {
  const [loading, setLoading] = useState(false);

  const handleRegister = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        email: values.email,
        password: values.password,
        username: `${values.fname} ${values.lname}`,
      };
      await DAV_APIS.auth.register(data, 'admin');
      Alert({ message: 'Registered Successfully', variant: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Alert({ message: 'Failed to register', variant: 'error' });
    }
  };
  return (
    <AuthCover title='Register'>
      <div className='p-5'>
        <Formik
          enableReinitialize
          initialValues={{
            fname: '',
            lname: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={registerSchema}
          onSubmit={async (values, { resetForm }) => {
            await handleRegister(values);

            resetForm();
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <RegisterForm />

              <div className='flex justify-center gap-2 my-3 pt-3'>
                <button type='submit' className='dav-button dav-button-primary' disabled={loading}>
                  {loading ? <Loader isButton /> : 'Register'}
                </button>
              </div>
            </form>
          )}
        </Formik>
        <div className='flex justify-center gap-2 pt-3'>
          Already a member?{' '}
          <Link to='/' className='underline hover:underline hover:text-secondary'>
            Login
          </Link>
        </div>
      </div>
    </AuthCover>
  );
};

export default Register;
