/* eslint-disable react/button-has-type */
import React from 'react';
import './style.css';

/**
 * @name Button
 * @description Button component
 * @param {string} variant - Button variant (primary, outline, secondary)
 * @param {string} type - Button type
 * @param {string} customClass - Custom class
 * @param {boolean} disabled - Button disabled
 * @param {string} children - Button children
 * @param {object} rest - Rest of the props
 * @returns {JSX.Element} - Button component
 */
const Button = (props) => {
  const { disabled, children, variant, type, customClass, ...rest } = props;

  let styles = '';
  switch (variant) {
    case 'primary':
      styles =
        'bg-primary text-white border-primary hover:bg-primary/80 hover:text-white hover:border-opacity-0 disabled:bg-tertiary disabled:border-tertiary';
      break;
    case 'outline':
      styles =
        'bg-white text-primary border-primary hover:bg-primary hover:text-white hover:border-primary';
      break;
    case 'secondary':
      styles =
        'bg-secondary text-white border-secondary hover:bg-secondary/80 hover:text-white hover:border-opacity-0 disabled:bg-tertiary disabled:border-tertiary';
      break;
    case 'outline-secondary':
      styles =
        'bg-white text-secondary border-secondary hover:bg-secondary hover:text-white hover:border-secondary disabled:bg-tertiary disabled:border-tertiary';
      break;
    case 'outline-primary':
      styles =
        'bg-white text-primary border-primary hover:bg-primary hover:text-white hover:border-primary disabled:bg-tertiary disabled:text-white disabled:border-tertiary';
      break;
    default:
      styles =
        'bg-primary text-white border-primary hover:bg-primary/80 hover:text-white hover:border-opacity-0 disabled:bg-tertiary disabled:border-tertiary';
      break;
  }

  return (
    <button
      disabled={disabled}
      className={`${
        customClass ?? ''
      } flex justify-center items-center font-bold cursor-pointer uppercase py-2 px-3 rounded-full font-Monteserrat border-2 disabled:bg-tertiary disabled:border-tertiary disabled:cursor-not-allowed ${styles}}`}
      type={type ?? 'button'}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
