import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { SubscribersProps } from '../constants';

let subscribersData: SubscribersProps[] = [];

const useSubscribers = (refresh?: number | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<SubscribersProps[]>([]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      if (!navigator.onLine) return;
      if (subscribersData?.length > 0 && !refresh) {
        setSubscribers(subscribersData);
        return;
      }
      setLoading(true);
      try {
        const res = await DAV_APIS.auth.get.getSubscribers();
        setSubscribers(res.data.Subscribers);
        subscribersData = res.data.Subscribers;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchSubscribers();
  }, [refresh]);

  return {
    loading,
    subscribers,
  };
};

export default useSubscribers;
