import { FC, useState, useEffect } from 'react';
import { CustomEditor, ImageUpload, Input } from '../../../../commons';
import PostBlocks from './PostBlocks';

interface NewPostFormProps {
  values: any;
  setFieldValue: any;
  edit?: boolean;
}
const PostForm: FC<NewPostFormProps> = ({ values, setFieldValue, edit }) => {
  const [initialValue, setInitialValue] = useState(values.postContent);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setFieldValue('imageFile', file);

    const image = URL.createObjectURL(file);
    setFieldValue('imageCover', image);
  };

  useEffect(() => {
    setFieldValue('postContent', initialValue);
  }, [initialValue]);

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-3 md:gap-3 items-end'>
        <div className='col-span-2'>
          <Input type='text' name='title' label='Post Title' />
        </div>
        <div className='col-span-1 mt-4 md:mt-0'>
          <ImageUpload tourImage={values.imageCover} tourImageHandler={handleImageChange} />
        </div>
      </div>
      <CustomEditor
        placeholder='Type description here...'
        onChange={setInitialValue}
        value={initialValue}
        name='postContent'
      />

      <PostBlocks setFieldValue={setFieldValue} values={values} edit={edit} />
    </div>
  );
};

export default PostForm;
