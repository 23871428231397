import { Navigate, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {
  ChangePasswordPage,
  Dashboard,
  ForgotPasswordPage,
  LoginPage,
  OverviewPage,
  RegisterPage,
  BookingsPage,
  ToursPage,
  NewTourPage,
  EditTourPage,
  PostsPage,
  NewPostPage,
  EditPostPage,
  ReviewsPage,
  CountriesPage,
  NewCountryPage,
  EditCountryPage,
  CategoriesPage,
  NewCategoryPage,
  EditCategoryPage,
  SubscribersPage,
  AgentsPage,
  Pages,
} from './components';
import RequestInterceptor from './adapters/axios';
import { AutoAuthenticate } from './store/actions';
import './App.css';
import { DAV_ROLES } from './constants';
import AgentOverview from './components/dashboard/agentPages/overview';
import CountryTours from './components/dashboard/agentPages/countryTours';

const App = () => {
  RequestInterceptor();
  const { isLoggedIn: isAuth, user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    AutoAuthenticate(dispatch);
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path='/' element={isAuth ? <Navigate to='/dashboard/overview' /> : <LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route path='/change-password/:resetToken' element={<ChangePasswordPage />} />
        <Route path='/dashboard/*' element={isAuth ? <Dashboard /> : <Navigate to='/' />}>
          <Route
            path='overview'
            element={user?.role === DAV_ROLES.AGENT ? <AgentOverview /> : <OverviewPage />}
          />
          <Route path='bookings' element={<BookingsPage />} />
          <Route path='tours' element={<ToursPage />} />
          <Route path='tours/new' element={<NewTourPage />} />
          <Route path='tours/edit' element={<EditTourPage />} />
          <Route path='safari-updates' element={<PostsPage />} />
          <Route path='safari-updates/new' element={<NewPostPage />} />
          <Route path='safari-updates/edit' element={<EditPostPage />} />
          <Route path='reviews' element={<ReviewsPage />} />
          <Route path='countries' element={<CountriesPage />} />
          <Route path='countries/new' element={<NewCountryPage />} />
          <Route path='countries/edit' element={<EditCountryPage />} />
          <Route path='categories' element={<CategoriesPage />} />
          <Route path='categories/new' element={<NewCategoryPage />} />
          <Route path='categories/edit' element={<EditCategoryPage />} />
          <Route path='subscribers' element={<SubscribersPage />} />
          <Route path='agents' element={<AgentsPage />} />
          <Route path='pages' element={<Pages />} />
          <Route path='tours/:countrySlug' element={<CountryTours />} />
        </Route>

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
};

export default App;
