import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../../commons';

const LoginForm: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className=''>
      <Input type='email' name='email' label='Email' />

      <Input
        handlePasswordVisibility={handlePasswordVisibility}
        type={showPassword ? 'text' : 'password'}
        showEye
        name='password'
        label='Password'
        autoComplete='current-password'
      />
      <div className='flex justify-end pt-1'>
        <Link
          to='/forgot-password'
          className='hover:underline hover:text-secondary'
        >
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
