/**
 * @name agentsRequests
 * @description Function used to make request to the tour endpoints
 * @param requestor
 * @returns {object} agentsRequests
 */
export const agentsRequests = (requestor: any) => ({
  /**
   * @name activateDeactivateAgent
   * @description Function used to make request to activate or deactivate an agent
   * @param {string} agentId
   * @param {string} action
   * @returns {Promise<any>} response
   */
  activateDeactivateAgent: async (agentId: string, action: string) => {
    const data = { agentId };
    const url = action === 'activate' ? `/users/activateAgent` : `/users/deactivateAgent`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },
  /**
   * @name get - handle all get requests for estimate
   */
  get: {
    /**
     * @name getAgents
     * @description Function used to get all posts
     * @param {string} type
     * @returns {object} response
     */
    getAgents: async () => {
      const url = `/users/getAllAgents`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },
  },
});
