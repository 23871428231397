import { useEffect, useState } from 'react';
import { PostProps } from '../constants/interfaces';
import { DAV_APIS } from '../adapters';

const postData = {
  posts: [],
  language: [],
};

const usePosts = (type: 'posts' | 'language', refresh?: number | null) => {
  const [posts, setPosts] = useState<PostProps[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPosts = async () => {
      if (!navigator.onLine) return;

      if (postData[type].length > 0 && !refresh) {
        setPosts(postData[type]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const res = await DAV_APIS.post.get.getPosts(type);
      if (res.status === 200) {
        setPosts(res.data.Posts);
        postData[type] = res.data.Posts;
      }
      setLoading(false);
    };
    getPosts();
  }, [type, refresh]);

  return { posts, loading };
};

export default usePosts;
