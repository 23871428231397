import React, { useState, FC } from 'react';
import classes from './NewKeyWord.module.css';
import Chip from '../Chip';

interface NewKeyWordProps {
  key_words: string[];
  setKeys: any;
}
const NewKeyWord: FC<NewKeyWordProps> = ({ key_words, setKeys }) => {
  const [newWord, setNewWord] = useState<string>('');
  const [isEdit, setIsEdit] = useState(false);
  const [index, setIndex] = useState<number>(0);

  const handleClick = (word: string, idx: number) => {
    setNewWord(word);
    setIndex(idx);
    setIsEdit(true);
  };

  const updateRow = (e: any) => {
    e.preventDefault();
    let copyWord: string = key_words[index];
    copyWord = newWord;
    key_words[index] = copyWord;
    setKeys([...key_words]);
    setIsEdit(false);
    setNewWord('');
  };

  const addRow = (e: any) => {
    e.preventDefault();
    const newEntry = newWord;
    if (newWord) {
      setKeys([...key_words, newEntry]);
    }
    setNewWord('');
  };

  const deleteRow = (e: any, id: number) => {
    e.stopPropagation();
    const copyWords = [...key_words];
    copyWords.splice(id, 1);
    setKeys(copyWords);
  };

  return (
    <div className={classes.keyword_wrapper}>
      <label htmlFor='keyword' className='mb-2 text-darkGreen font-Monteserrat'>
        Key Words
      </label>
      {key_words.length > 0 && (
        <p className='text-sm text-black/50'>**Click any keyword to edit it**</p>
      )}
      <div className='flex gap-1 flex-wrap m-1'>
        {key_words.length > 0 &&
          key_words.map((word, idx) => (
            <Chip
              onClick={() => handleClick(word, idx)}
              key={word}
              label={word}
              onDelete={(e) => deleteRow(e, idx)}
            />
          ))}

        <form className={classes.key_word_form}>
          <input
            id='keyword'
            className={classes.key_word_input_field}
            name='keyword'
            value={newWord}
            onChange={(e) => setNewWord(e.target.value)}
            placeholder='Enter new keyword...'
            required
          />
          <button
            onClick={isEdit ? updateRow : addRow}
            type='submit'
            className={classes.keys_submit_btn}
          >
            {isEdit ? 'Edit' : 'Add'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewKeyWord;
