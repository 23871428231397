import { axios } from './axios';
import requestAdapter from './requests';

interface RequestorConfig {
  url: string;
  data?: any;
  headers?: any;
  method?: string;
  body?: any;
  [key: string]: any;
}
/**
 * @name Requestor
 * @param {RequestConfig} config - Request settings.
 */

export const Requestor = async (config: RequestorConfig) => {
  const { url, data, headers } = config;
  const res = await axios(url, {
    method: 'GET',
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
    ...config,
  });
  return res;
};

const DAV_APIS = requestAdapter(Requestor);

export { DAV_APIS };
