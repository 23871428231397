import React, { FC } from 'react';
import { RiTimerLine } from 'react-icons/ri';
import { GoLinkExternal } from 'react-icons/go';
import { DAV_MAIN_DOMAIN, TourProps } from '../../../../../constants';
import classes from './TourCard.module.css';

interface TourCardProps {
  tour: TourProps;
  className?: string;
}

const TourCard: FC<TourCardProps> = ({ tour, className = '' }) => {
  const { imageCover, name, duration, description, slug, price } = tour;

  return (
    <div className={`${classes.tour_card_wrapper} ${className} hover:shadow-lg`}>
      <div className={`${classes.tour_card_header}`}>
        <div className={classes.tour_card_header_inner}>
          <img alt={name} src={imageCover} />
        </div>
      </div>
      <div className={classes.tour_card_body}>
        <div className={classes.tour_title}>
          <h4 title={name}>{name}</h4>
        </div>
        <span className={classes.tour__date}>
          <RiTimerLine /> {duration} DAYS - {duration - 1} NIGHTS
        </span>
        <div className={classes.tour__description}>
          <div
            className='dav__single_tour_description'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      <div className={classes.tour__footer}>
        <div className='flex items-center gap-1 px-2'>
          <a
            href={`${DAV_MAIN_DOMAIN}/tours/${slug}`}
            className='text-primary hover:text-tertiary'
            target='_blank'
            rel='noopener noreferrer'
          >
            View Tour
          </a>
          <GoLinkExternal className='text-sm' />
        </div>
        {price > 0 && (
          <div className={classes.tour__price}>
            <div className={classes.tour__price_inner}>
              <span>${price}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TourCard;

// export { default as TourCardSkeleton } from './TourCardSkeleton';
