import { FC, useState } from 'react';
import { PostBlockProps } from '../../../../../../constants';
import { Modal } from '../../../../../commons';
import { scrollToElement } from '../../../../../../utils';
import PostBlockCard from './PostBlockCard';
import NewBlockForm from './NewBlockForm';

interface PostBlocksProps {
  values: any;
  setFieldValue: any;
  edit?: boolean;
}

const clearNewBlockForm = (setFieldValue: any) => {
  setFieldValue('blockTitle', '');
  setFieldValue('blockDescription', '');
};

const PostBlocks: FC<PostBlocksProps> = ({ values, setFieldValue }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleEditHandler = () => {
    if (selectedId !== null) {
      const block: PostBlockProps = {
        title: values.blockTitle,
        description: values.blockDescription,
      };
      const newBlocks = values.postBlocks;
      newBlocks[selectedId] = block;
      setFieldValue('postBlocks', newBlocks);
    }
    setIsEdit(false);
    clearNewBlockForm(setFieldValue);
  };

  const handleAddItinary = () => {
    if (isEdit) {
      handleEditHandler();
      return;
    }
    setFieldValue('postBlocks', [
      ...values.postBlocks,
      {
        title: values.blockTitle,
        description: values.blockDescription,
      },
    ]);
    clearNewBlockForm(setFieldValue);
  };

  const onEditHandler = (id: number) => {
    setIsEdit(true);
    setSelectedId(id);
    const block = values.postBlocks[id];
    setFieldValue('blockTitle', block.title);
    setFieldValue('blockDescription', block.description);
    scrollToElement('edit');
  };

  const onDeleteHandler = (id: number) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleDeleteItinary = () => {
    const newBlocks = values.postBlocks;
    newBlocks.splice(selectedId, 1);
    setFieldValue('postBlocks', newBlocks);
    setShowModal(false);
  };

  return (
    <>
      <div className='bg-[#f5f5f5] rounded-md p-3 my-3' id='itinaries'>
        <div className='flex justify-between items-center py-2'>
          <h5 className='mb-2 italic text-black'>
            Post Blocks {values.postBlocks.length ? `(${values.postBlocks.length})` : ''}
          </h5>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
          {values.postBlocks.map((block: PostBlockProps, idx: number) => (
            <div className='col-span-1' key={block.title}>
              <PostBlockCard
                idx={idx}
                {...block}
                onDeleteHandler={() => onDeleteHandler(idx)}
                onEditHandler={() => onEditHandler(idx)}
              />
            </div>
          ))}
        </div>
        <div id='edit'>
          {values.postBlocks.length > 0 && (
            <div className='flex justify-center py-5'>
              <hr className='w-4/5 text-center' />
            </div>
          )}
          <NewBlockForm
            handleAddItinary={handleAddItinary}
            isEdit={isEdit}
            handleCancelEdit={() => {
              setIsEdit(false);
              clearNewBlockForm(setFieldValue);
            }}
            values={values}
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
      <Modal
        size='sm'
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setIsEdit(false);
        }}
        onSuccess={handleDeleteItinary}
        successText='Delete'
        loadingText='Saving...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Post Block</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete this Block? This action is permanent and can not be
            undone.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default PostBlocks;
