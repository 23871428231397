import React from 'react';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import Button from '../Button';

const Pagination = ({
  pageIndex,
  pageOptions,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
}) => (
  <div className='flex items-center justify-between p-3'>
    <div className='text-primary'>
      <p>
        Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
      </p>
    </div>
    <div className='flex items-center justify-between' style={{ gap: 6 }}>
      <Button
        variant='outline-primary'
        onClick={previousPage}
        disabled={!canPreviousPage}
        style={{ gap: 2 }}
      >
        <RxDoubleArrowLeft h={8} w={8} fontWeight={600} />
        Previous
      </Button>

      <Button
        variant='outline-primary'
        onClick={nextPage}
        disabled={!canNextPage}
        style={{ gap: 2 }}
      >
        Next
        <RxDoubleArrowRight h={8} w={8} fontWeight={600} />
      </Button>
    </div>
  </div>
);

export default Pagination;
