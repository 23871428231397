import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { createPortal } from 'react-dom';

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  onCancel?: () => void;
  onSuccess?: () => void;
  cancelText?: string;
  successText?: string;
  loadingText?: string;
  hideCancelButton?: boolean;
  hideSuccessButton?: boolean;
  loading?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  open = false,
  onClose,
  size = 'sm',
  onCancel,
  onSuccess,
  cancelText,
  successText,
  loadingText,
  hideCancelButton,
  hideSuccessButton,
  loading,
}) => {
  const modalRoot = open ? document.getElementById('modal-root') : null;

  // Create a new div element to contain the modal content
  const el = document.createElement('div');

  useEffect(() => {
    if (!modalRoot) {
      document.body.classList.remove('modal-open');
      return () => {};
    }
    modalRoot?.appendChild(el);
    document.body.classList.add('modal-open');
    return () => {
      modalRoot?.removeChild(el);
      document.body.classList.remove('modal-open');
    };
  }, [el, modalRoot, open]);

  return createPortal(
    <div className='dav-modal-overlay' onClick={onClose}>
      <div className={`dav-modal-container dav-modal-container-${size}`}>
        <div
          onClick={onClose}
          className='dav-modal-container-close-button bg-gray-200 text-tertiary hover:text-errorText'
        >
          <MdClose className='text-3xl' />
        </div>
        <div className='dav-modal-content' onClick={(e) => e.stopPropagation()}>
          {children}

          {hideCancelButton && hideSuccessButton ? null : (
            <div
              className={`flex ${
                hideCancelButton || hideSuccessButton ? 'justify-end' : 'justify-between'
              } gap-3 mt-5`}
            >
              <button
                disabled={loading}
                onClick={onCancel ?? onClose}
                type='button'
                className='bg-errorBg text-errorText hover:opacity-80 rounded-lg py-2 px-3 w-full shadow-sm hover:shadow-md'
              >
                {cancelText ?? 'Cancel'}
              </button>
              <button
                disabled={loading}
                onClick={onSuccess}
                type='button'
                className='bg-successBg text-successText hover:opacity-50 rounded-lg py-2 px-3 w-full shadow-sm hover:shadow-md'
              >
                {loading ? `${loadingText ?? 'Saving...'}` : successText ?? 'Save'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>,
    el,
  );
};

export default Modal;
