import { Formik } from 'formik';
import { useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { countrySchema } from '../../../../../constants';
import { DAV_APIS } from '../../../../../adapters';
import { Alert, SEO } from '../../../../commons';
import CountryForm from '../CountryForm';
import { useCountry, useSearchQuery } from '../../../../../hooks';
import DashboardLoader from '../../../sections/Loader/DashboardLoader';

const EditCountryPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const countryId = useSearchQuery('country') as string;

  const { country, loading: isFetching } = useCountry(countryId);

  const newCountrySubmitHandler = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        name: values.name,
        title: values.title,
        description: values.description,
        countrySummary: values.countrySummary,
        countryImage: values.imageFile,
      };
      const res = await DAV_APIS.country.editCountry(data, countryId);
      setLoading(false);
      if (res.status === 200) {
        Alert({ variant: 'success', message: 'Country created successfully' });
        navigate('/dashboard/countries');
      }
    } catch (error) {
      setLoading(false);
      Alert({ variant: 'error', message: 'Failed to create country, Please try again' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full py-2 md:p-5'>
      <div className='flex justify-between items-center'>
        <button
          onClick={() => navigate('/dashboard/countries')}
          type='button'
          className='bg-transparent flex gap-1 items-center'
        >
          <MdArrowBackIosNew /> Back
        </button>
      </div>
      <div className='bg-white rounded-md p-5 my-5'>
        <h2 className='text-xl font-semibold mb-2 text-center'>Add New Country</h2>

        {isFetching ? (
          <DashboardLoader />
        ) : (
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                name: country.name || '',
                title: country.title || '',
                description: country.description || '',
                imageCover: country.countryImage || '',
                imageFile: '',
                countrySummary: country.summary || '',
              }}
              validationSchema={countrySchema}
              onSubmit={async (values, { resetForm }) => {
                await newCountrySubmitHandler(values);
                resetForm();
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <CountryForm values={values} setFieldValue={setFieldValue} />
                  <div className='flex justify-center gap-2 my-3 pt-3'>
                    <button
                      type='submit'
                      className='dav-button dav-button-primary w-auto'
                      disabled={loading || !dirty || !isValid}
                    >
                      {loading ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <SEO
        isEdit
        pageType='country'
        initialData={{
          title: country.name,
          description: country.description,
          image: country.countryImage,
          slug: country.slug,
        }}
      />
    </div>
  );
};

export default EditCountryPage;
