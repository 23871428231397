const isLocalhost = process.env.NODE_ENV === 'development';
export const baseUrl = isLocalhost ?
  'http://127.0.0.1:3001/api/v1'
  : 'https://api.davsafaris.com/api/v1';

export const DAV_MAIN_DOMAIN = 'https://davsafaris.com';

export const DAV_ROLES = {
  ADMIN: 'admin',
  AGENT: 'agent',
};

export const CURRENT_VERSION = '1.2.24';

export const FILE_UPLOAD_BOX_STYLES =
  'w-full border border-dashed border-[#cbd6e2]  h-[230px] bg-[#f5f8fa] rounded-md flex justify-center items-center cursor-pointer hover:border-secondary';

export * from './schemas';
export * from './interfaces';
