import { FC } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';

interface ChipProps {
  label: string;
  onClick: () => void;
  onDelete: (e: any) => void;
}

const Chip: FC<ChipProps> = ({ label, onClick, onDelete }) => (
  <div
    className='flex gap-2 items-center bg-primary px-2 rounded-full text-white cursor-pointer'
    onClick={onClick}
  >
    {label}
    <IoMdCloseCircle onClick={onDelete} />
  </div>
);

export default Chip;
