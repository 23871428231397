import React, { useState } from 'react';
import { Table, subscribersColumns } from '../../../commons/Table';
import { useSubscribers } from '../../../../hooks';
import { Alert, Modal } from '../../../commons';
import { DAV_APIS } from '../../../../adapters';
import { SubscribersProps } from '../../../../constants';

const Subscribers = () => {
  const [selected, setSelected] = useState({} as SubscribersProps);
  const [refresh, setRefresh] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteHandler = (e: any, subscriberId: SubscribersProps) => {
    e.stopPropagation();
    setSelected(subscriberId);
    setShowModal(true);
  };

  const handleDeleteTour = async () => {
    setIsLoading(true);
    try {
      const res = await DAV_APIS.auth.deleteSubscriber(selected.id as string);
      if (res.status === 204) {
        Alert({ message: 'Deleted Successfully', variant: 'success' });
      }
      setIsLoading(false);
      setShowModal(false);
      setRefresh(Math.random());
    } catch (error) {
      setIsLoading(false);
      setShowModal(false);
      Alert({ message: 'Failed to delete tour', variant: 'error' });
    }
  };

  const { subscribers, loading } = useSubscribers(refresh);

  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Newsletter Subscribers</h1>
      {/* @ts-ignore */}
      <Table
        columns={subscribersColumns}
        data={subscribers}
        loading={loading}
        onDeleteHandler={onDeleteHandler}
      />
      <Modal
        size='sm'
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={handleDeleteTour}
        loading={isLoading}
        successText='Delete'
        loadingText='Deleting...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Subscriber</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete{' '}
            <span className='text-primary'>{selected?.name ?? selected?.email}</span> from the list?
            This action is permanent and can not be undone.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Subscribers;
