export const scrollToElement = (eleId: string) => {
  const targetElement = document.getElementById(eleId)!;
  const headerEle = document.getElementById('header')!;
  const headerHeight = headerEle.offsetHeight;
  const scrollToPosition = targetElement.offsetTop - headerHeight;
  window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
};

export const clearNewItinaryForm = (setFieldValue: any) => {
  setFieldValue('day', '');
  setFieldValue('itinaryTitle', '');
  setFieldValue('itinaryDescription', '');
  setFieldValue('meal_plan', '');
  setFieldValue('accommodation_plan', '');
};

export const getCountryFlag = async (countryName: string) => {
  const countryNameLower = countryName.toLowerCase().trim();
  const res = await fetch(
    `https://restcountries.com/v3.1/name/${countryNameLower}?fullText=true&fields=flags`,
  );
  const data = await res.json();

  return data[0].flags.png;
};
