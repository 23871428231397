/* eslint-disable arrow-body-style */
/* eslint-disable  react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter } from 'react-table';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { GoLinkExternal } from 'react-icons/go';
import { BiTrash } from 'react-icons/bi';
import Pagination from './TablePagination';
import Modal from '../Modal';
import './index.css';
import SearchInput from '../SearchInput';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type='checkbox' ref={resolvedRef} {...rest} />;
});

const TableComponent = ({
  data,
  columns,
  onViewHandler,
  onEditHandler,
  onDeleteHandler,
  hideActions,
  hidePagination,
  emptyText,
  placeholder,
  defaultSortBy,
  loading,
  advancedSearchComponent,
  recordName,
  viewExternal,
}) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!hideActions) {
      const actionColumn = {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        center: true,
        Cell: ({ row }) => (
          <div className='flex items-center justify-center gap-1'>
            {onViewHandler && (
              <span
                title='View'
                onClick={(e) => onViewHandler(e, row.original)}
                className='hover:bg-black/5 flex justify-center items-center  rounded-full h-8 w-8 cursor-pointer text-[#3366CC] hover:text-primary'
              >
                {viewExternal ? <GoLinkExternal /> : <AiOutlineEye />}
              </span>
            )}
            {onEditHandler && (
              <span
                title='Edit'
                onClick={(e) => onEditHandler(e, row.original)}
                className='hover:bg-black/5 flex justify-center items-center rounded-full h-8 w-8 cursor-pointer text-[#3366CC] hover:text-primary'
              >
                <AiOutlineEdit />
              </span>
            )}
            {onDeleteHandler && (
              <span
                title='Delete'
                onClick={(e) => onDeleteHandler(e, row.original)}
                className='hover:bg-black/5 flex justify-center items-center  rounded-full h-8 w-8 cursor-pointer text-errorText hover:text-[#ff0000]'
              >
                <BiTrash />
              </span>
            )}
          </div>
        ),
      };
      setTableColumns([...columns, actionColumn]);
    } else {
      setTableColumns(columns);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const modifiedColumns = useMemo(() => tableColumns, [tableColumns]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    setGlobalFilter,
    state: {
      pageIndex,
      // selectedRowIds
    },
  } = useTable(
    {
      columns: modifiedColumns,
      data,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: [{ id: defaultSortBy ?? 'createdAt', desc: true }],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.columns.push((cols) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...cols,
      ]);
    },
  );

  const getNoDataText = () => {
    if (loading) return 'Loading data...';
    if (searchTerm) {
      return (
        <span>
          No search result for <strong className='font-semibold'>{searchTerm}</strong>
        </span>
      );
    }

    return emptyText ?? 'No data';
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    setGlobalFilter(value);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <div className='w-full'>
      <Modal size='sm' open={showModal} onClose={() => setShowModal(false)}>
        <div className='flex flex-col gap-2'>{advancedSearchComponent}</div>
      </Modal>
      <div className='flex justify-between items-center px-3'>
        <div className='flex items-end gap-1'>
          <h2 className='text-3xl text-center text-[#393c5c]'>{data?.length}</h2>
          <span className='pb-1 text-primary capitalize'>{recordName ?? 'Records'}</span>
          {selectedFlatRows.length > 0 && (
            <span className='pb-1 text-secondary'>({selectedFlatRows.length} selected)</span>
          )}
        </div>
        <div className='flex items-center gap-1 text-primary'>
          <div className='hidden md:block'>
            <SearchInput
              customClass='focus:w-80'
              onChangeHandler={handleSearch}
              placeholder={placeholder}
            />
          </div>
          <div className='flex items-center text-primary gap-1'>
            {advancedSearchComponent && (
              <button
                onClick={() => setShowModal(true)}
                type='button'
                className='text-primary rounded-md py-1 md:py-2 px-2 hover:bg-[#f5f8fa] border border-[#cbd6e2] focus:outline-none focus:border-primary hover:border-primary leading-tight'
              >
                Advanced Search
              </button>
            )}
            <select
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className='appearance-none text-primary rounded-md py-1 md:py-1 px-2 border border-[#cbd6e2] focus:outline-none focus:border-primary hover:border-primary leading-tight'
            >
              {[5, 10, 20, 30, 40, 50].map((pSize) => (
                <option key={pSize} value={pSize}>
                  {pSize}
                </option>
              ))}
            </select>
            /Page
          </div>
        </div>
      </div>
      <div className='w-full px-2 md:hidden'>
        <SearchInput onChangeHandler={handleSearch} placeholder={placeholder} />
      </div>
      <div className='table-wrapper'>
        <table {...getTableProps()} className='data-table table-hover text-nowrap mt-2 w-full'>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div
                      style={{
                        gap: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: column.center ? 'center' : '',
                      }}
                    >
                      {column.render('Header')}

                      {column.canSort && (
                        <div className='flex items-center justify-center'>
                          {column.isSortedDesc && (
                            <AiOutlineArrowDown className='text-primary font-bold' />
                          )}
                          {column.isSorted && !column.isSortedDesc && (
                            <AiOutlineArrowUp className='text-primary font-bold' />
                          )}
                          {!column.isSorted && !column.isSortedDesc && (
                            <>
                              <AiOutlineArrowDown />
                              <AiOutlineArrowUp />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={onViewHandler ? (e) => onViewHandler(e, row.original) : null}
                  className={onViewHandler ? 'cursor-pointer' : ''}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column?.className}
                      style={cell.column?.style}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {page.length < 1 && (
        <div className='flex items-center justify-center no-results-wrapper text-primary bg-white'>
          {getNoDataText()}
        </div>
      )}
      {!hidePagination && data.length > 0 && (
        <Pagination
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />
      )}
    </div>
  );
};

export default TableComponent;
