import { FC, useState } from 'react';
import { Formik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthCover from '../../../commons/AuthCover';
import { ChangePasswordForm } from '../../authForms';
import { Alert, Loader } from '../../../commons';
import { changePasswordSchema } from '../../../../constants';
import { DAV_APIS } from '../../../../adapters';

const ChangePassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const { resetToken } = useParams<{ resetToken: string }>();
  const navigate = useNavigate();

  const handleChangePassword = async (values: any) => {
    setLoading(true);
    try {
      await DAV_APIS.auth.resetPassword(values.password, resetToken as string);
      setLoading(false);
      navigate('/login');
      Alert({ message: 'Password Changed Successfully', variant: 'success' });
    } catch (error) {
      setLoading(false);
      Alert({ message: 'Failed to change password', variant: 'error' });
    }
  };

  return (
    <AuthCover title='Change Password'>
      <div className='p-5'>
        <Formik
          enableReinitialize
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={changePasswordSchema}
          onSubmit={async (values, { resetForm }) => {
            await handleChangePassword(values);

            resetForm();
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ChangePasswordForm />

              <div className='flex justify-center gap-2 my-3 pt-3'>
                <button type='submit' className='dav-button dav-button-primary' disabled={loading}>
                  {loading ? <Loader isButton /> : 'Save Changes'}
                </button>
              </div>
            </form>
          )}
        </Formik>
        <div className='flex justify-center gap-2 pt-3'>
          Remembered Password?{' '}
          <Link to='/login' className='underline hover:underline hover:text-secondary'>
            Login
          </Link>
        </div>
      </div>
    </AuthCover>
  );
};

export default ChangePassword;
