import { useState, useEffect } from 'react';

const MOBILE_MAX_WIDTH = 768; // Adjust this value based on your needs

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(true);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
    }

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
