import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { ReviewProps } from '../constants';

let reviewsData: ReviewProps[] = [];

const useReviews = (refresh?: number | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<ReviewProps[]>([]);

  useEffect(() => {
    const fetchReviews = async () => {
      if (!navigator.onLine) return;
      if (reviewsData.length > 0 && !refresh) {
        setReviews(reviewsData);
        return;
      }
      setLoading(true);
      try {
        const res = await DAV_APIS.tour.get.getReviews();
        setReviews(res.data.reviews);
        reviewsData = res.data.reviews;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [refresh]);

  return {
    loading,
    reviews,
  };
};

export default useReviews;
