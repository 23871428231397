import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { TourProps } from '../constants/interfaces';

const useCountryTours = (country: string, refresh: number | null) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tours, setTours] = useState<TourProps[]>([]);

  useEffect(() => {
    const fetchTours = async () => {
      if (!country) return;
      if (!navigator.onLine) return;
      setLoading(true);
      try {
        const res = await DAV_APIS.tour.get.getAllToursByCountry(country);
        setTours(res.data.tours);
        setLoading(false);
      } catch (error: any) {
        if (error.response?.status === 404) {
          setTours([]);
        }
        setLoading(false);
      }
    };

    fetchTours();
  }, [country, refresh]);

  return {
    loading,
    tours,
  };
};

export default useCountryTours;
