import React, { FC, useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { CustomEditor, Input } from '../../../../../commons';
import Button from '../../../../../commons/Button';

interface NewBlockFormProps {
  handleAddItinary: () => void;
  isEdit?: boolean;
  handleCancelEdit?: () => void;
  values: any;
  setFieldValue: any;
}

const NewBlockForm: FC<NewBlockFormProps> = ({
  handleAddItinary,
  isEdit,
  handleCancelEdit,
  values,
  setFieldValue,
}) => {
  const [initialValue, setInitialValue] = useState(values.blockDescription);

  useEffect(() => {
    setFieldValue('blockDescription', initialValue);
  }, [initialValue]);

  useEffect(() => {
    setInitialValue(values.blockDescription);
  }, [values.blockDescription]);

  return (
    <div id='edit' className='p-3'>
      <Input type='text' name='blockTitle' label='Block Title' customClass='bg-white' />
      <CustomEditor
        placeholder='Type block description here...'
        onChange={setInitialValue}
        value={initialValue}
        customClass='itinary-editor'
        name='blockDescription'
      />

      <div className='flex justify-end items-center gap-3 w-full'>
        {isEdit ? (
          <>
            <button
              onClick={handleCancelEdit}
              type='button'
              className='flex justify-center items-center bg-errorText text-white font-semibold hover:opacity-80 rounded-full py-2 px-3 w-10 h-10 shadow-sm hover:shadow-md'
            >
              <RxCross1 />
            </button>
            <button
              onClick={handleAddItinary}
              type='button'
              className='bg-primary text-white font-semibold hover:opacity-50 rounded-full py-2 px-3 w-auto shadow-sm hover:shadow-md'
            >
              Save
            </button>
          </>
        ) : (
          <Button type='button' onClick={handleAddItinary}>
            Add
          </Button>
        )}
      </div>
    </div>
  );
};

export default NewBlockForm;
