import { useState } from 'react';
import { Table, reviewsColumns } from '../../../commons/Table';
import { useReviews } from '../../../../hooks';
import { Alert, Modal } from '../../../commons';
import { ReviewProps } from '../../../../constants';
import { DAV_APIS } from '../../../../adapters';

const Reviews = () => {
  const [selected, setSelected] = useState({} as ReviewProps);
  const [refresh, setRefresh] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteHandler = (e: any, review: ReviewProps) => {
    e.stopPropagation();
    setSelected(review);
    setShowModal(true);
  };

  const handleDeleteTour = async () => {
    setIsLoading(true);
    try {
      const res = await DAV_APIS.tour.deleteReviewById(selected.id as string);
      if (res.status === 204) {
        Alert({ message: 'Deleted Successfully', variant: 'success' });
      }
      setIsLoading(false);
      setShowModal(false);
      setRefresh(Math.random());
    } catch (error) {
      setIsLoading(false);
      setShowModal(false);
      Alert({ message: 'Failed to delete review', variant: 'error' });
    }
  };
  const { reviews, loading } = useReviews(refresh);
  return (
    <div className='w-full md:p-5'>
      <h1 className='text-2xl font-semibold my-2 italic'>Client Reviews</h1>
      {/* @ts-ignore */}
      <Table
        columns={reviewsColumns}
        data={reviews}
        loading={loading}
        onDeleteHandler={onDeleteHandler}
      />
      <Modal
        size='sm'
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={handleDeleteTour}
        loading={isLoading}
        successText='Delete'
        loadingText='Deleting...'
      >
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl font-semibold mb-2'>Delete Review</h1>
          <p className='text-tertiary mb-2'>
            Are you sure you wish to delete a review by{' '}
            <span className='text-primary'>{selected?.user_name ?? selected?.email}</span> from the
            list? This action is permanent and can not be undone.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Reviews;
