import { DAV_ROLES } from '../../constants';

/**
 * @name authRequests
 * @description Function used to make request to the auth endpoints
 * @param requestor
 * @returns {object} authRequests
 */
export const authRequests = (requestor: any) => ({
  /**
   * @name register
   * @description Function used to make request to the register user
   * @param {object} data
   * @param {string} role
   * @returns
   */
  register: async (data: any, role: string) => {
    const url = `/users/signup${role === DAV_ROLES.AGENT ? `?role=${DAV_ROLES.AGENT}` : ''}`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name login
   * @description Function used to make request to login user
   * @param {object} data
   * @returns {Promise<any>} response
   */
  login: async (data: any) => {
    const url = `/users/login`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name forgotPassword
   * @description Function used to make request to reset user password
   * @param {string} email
   * @returns {Promise<any>} response
   */
  forgotPassword: async (email: string) => {
    const url = `/users/forgotPassword`;
    const response = await requestor({
      method: 'POST',
      url,
      data: { email },
    });
    return response;
  },

  /**
   * @name resetPassword
   * @description Function used to make request to reset user password
   * @param {string} token
   * @param {string} password
   * @returns {Promise<any>} response
   */
  resetPassword: async (password: string, token: string) => {
    const url = `/users/resetPassword/${token}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data: { password },
    });
    return response;
  },

  /**
   * @name deleteSubscriber
   * @param {string} subscriberId
   * @description Function used to make request to reset user password
   *
   * @returns {Promise<any>} response
   */
  deleteSubscriber: async (subscriberId: string) => {
    const url = `/subscribers/deleteSubscriber/${subscriberId}`;
    const response = await requestor({
      method: 'DELETE',
      url,
    });
    return response;
  },

  /**
   * @name contactUs
   * @description Function used to make request to book a tour
   * @param {object} data
   * @returns {<Promise>} response
   */
  contactUs: async (data: any) => {
    const url = `/subscribers/contactUs`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  get: {
    /**
     * @name getSubscribers
     * @description Function used to get all subscribers
     * @returns {object} response
     */
    getSubscribers: async () => {
      const url = `subscribers/getAllSubscribers`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },
  },
});
