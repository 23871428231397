import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password should have atleast 6 characters')
    .required('Password is required'),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
});

export const registerSchema = yup.object().shape({
  fname: yup.string().required('First name is required'),
  lname: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password should have atleast 6 characters')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password should have atleast 6 characters')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords do not match')
    .required('Confirm Password is required'),
});

export const updateProfileSchema = yup.object().shape({
  fname: yup.string().required('First name is required'),
  lname: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup
    .string()
    .min(10, 'Phone number should be 10 characters')
    .required('Phone number is required'),
  address: yup.string().required('Address is required'),
});

export const tourSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  price: yup.number().required('Price is required'),
  country: yup.string().required('Country is required'),
  category: yup.string().required('Category is required'),
  tourActivities: yup.string().required('Tour Highlights is required'),
  days: yup.number().required('Days is required'),
  imageCover: yup.string().required('Tour Image is required'),
});

export const postSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  postContent: yup.string().required('Description is required'),
  imageCover: yup.string().required('Post Image is required'),
});

export const countrySchema = yup.object().shape({
  title: yup.string().required('Country headline is required'),
  name: yup.string().required('Country Name is required'),
  description: yup.string().required('Description is required'),
  imageCover: yup.string().required('Country Image is required'),
});

export const categorySchema = yup.object().shape({
  title: yup.string().required('Country headline is required'),
  description: yup.string().required('Description is required'),
  imageCover: yup.string().required('Country Image is required'),
  country: yup.string().required('Country is required'),
});

export const seoSchema = yup.object().shape({
  title: yup.string().required('Meta Title is required').max(200),
  description: yup.string().required('Meta Description is required').max(300),
  image: yup.string(),
});

export const messageSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email().required('Email is required'),
  message: yup.string().required('Message is required'),
});
