import React, { FC } from 'react';

interface AuthCoverProps {
  children: React.ReactNode;
  title?: string;
}

const AuthCover: FC<AuthCoverProps> = ({ children, title }) => (
  <div className='dav-auth-cover min-h-screen bg-primary pt-4 pb-4 w-full flex flex-col items-center justify-center'>
    <div className='dav-bg-wrapper bg-white text-tertiary rounded-md shadow-sm'>
      <div className='dav-home-logo w-full flex justify-center items-center flex-col text-center'>
        <img src='/assets/Dav-safaris-transparent-logo.png' alt='Dav Safaris' />
        {title && <h1 className='text-center italic'>{title}</h1>}
      </div>
      <div className='md-wrapper-content w-full'>{children}</div>
    </div>
  </div>
);

export default AuthCover;
