import { FC } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import classes from './ImageUpload.module.css';

interface ImageUploadProps {
  tourImageHandler: (e: any) => void;
  tourImage: string;
  isPost?: boolean;
}

const ImageUpload: FC<ImageUploadProps> = ({ tourImageHandler, tourImage, isPost = false }) => {
  const TourImageHandler = () => {
    const fileInput = document.getElementById(
      isPost ? 'post_image_input_change' : 'tour_image_input_change',
    ) as HTMLInputElement;
    fileInput.click();
  };

  return (
    <section
      className={classes.dav__cover_image}
      onClick={TourImageHandler}
      style={{
        backgroundImage: `url(${tourImage?.length > 0 ? tourImage : ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        minHeight: '134px',
      }}
    >
      <div
        className={`flex justify-center flex-col items-center ${
          tourImage?.length > 0 ? classes.dav__cover_image_content : ''
        }`}
      >
        <AiOutlineCloudUpload className='text-2xl' />
        <p>{tourImage?.length > 0 ? `Change  cover image` : `Upload cover image`}</p>
        <input
          type='file'
          hidden
          accept='image/*'
          title=''
          value=''
          id={isPost ? 'post_image_input_change' : 'tour_image_input_change'}
          onChange={tourImageHandler}
        />
      </div>
    </section>
  );
};

export default ImageUpload;
