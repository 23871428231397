/**
 * @name countryRequests
 * @description Function used to make request to the tour endpoints
 * @param requestor
 * @returns {object} countryRequests
 */
export const countryRequests = (requestor: any) => ({
  /**
   * @name createCountry
   * @description Function used to make request to create a country
   * @param {object} data
   * @returns {Promise<any>} response
   */
  createCountry: async (data: any) => {
    const url = `/countries/create`;
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('summary', data.countrySummary);
    formData.append('description', data.description);
    formData.append('title', data.title);
    formData.append('file', data.countryImage);
    const response = await requestor({
      method: 'POST',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name editCountry
   * @description Function used to make request to edit a country
   * @param {object} data
   * @param {string} countryId
   * @returns {Promise<any>} response
   */
  editCountry: async (data: any, countryId: string) => {
    const url = `/countries/updateCountry/${countryId}`;
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('summary', data.countrySummary);
    formData.append('description', data.description);
    formData.append('title', data.title);
    formData.append('file', data.countryImage);
    const response = await requestor({
      method: 'PATCH',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name deleteCountryById
   * @description Function used to make request to delete a country
   * @param {string} countryId
   * @returns {object} response
   */
  deleteCountryById: async (countryId: string) => {
    const url = `/countries/deleteCountry/${countryId}`;
    const response = await requestor({
      method: 'DELETE',
      url,
    });
    return response;
  },

  /**
   * @name createCategory
   * @description Function used to make request to create a category
   * @param {object} data
   * @returns {Promise<any>} response
   */
  createCategory: async (data: any) => {
    const url = `/categories/create`;
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('value', data.value);
    formData.append('file', data.categoryImage);
    formData.append('description', data.description);
    formData.append('country', data.country);
    const response = await requestor({
      method: 'POST',
      url,
      data: formData,
    });
    return response;
  },

  /**
   * @name editCategory
   * @description Function used to make request to edit a category
   * @param {object} data
   * @param {string} categoryId
   * @returns {Promise<any>} response
   * @todo check if formData is needed and switch to it
   */
  editCategory: async (data: any, categoryId: string) => {
    const url = `/categories/updateTourCategory/${categoryId}`;
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('value', data.value);
    formData.append('file', data.categoryImage);
    formData.append('description', data.description);
    formData.append('country', data.country);
    const response = await requestor({
      method: 'PATCH',
      url,
      data,
    });
    return response;
  },

  /**
   * @name deleteCategoryById
   * @description Function used to make request to delete a category
   * @param {string} categoryId
   * @returns {object} response
   */
  deleteCategoryById: async (categoryId: any) => {
    const url = `/categories/deleteTourCategory/${categoryId}`;
    const response = await requestor({
      method: 'DELETE',
      url,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for estimate
   */
  get: {
    /**
     * @name getCountries
     * @description Function used to get all bookings
     * @returns {object} response
     */
    getCountries: async () => {
      const url = `/countries/getAllCountries`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getCountryBySlug
     * @description Function used to make request to get a country by slug
     * @param {string} slug
     * @returns {Promise<any>} response
     */
    getCountryBySlug: async (slug: string) => {
      const url = `/countries/getCountryBySlug/${slug}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
    /**
     * @name getCountryById
     * @description Function used to make request to get a country by an id
     * @param {string} id
     * @returns {Promise<any>} response
     */
    getCountryById: async (countryId: string) => {
      const url = `/countries/${countryId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getCategories
     * @description Function used to get all categories
     * @returns {object} response
     */
    getCategories: async () => {
      const url = `/categories/getAllTourCategories`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getCountryCategories
     * @description Function used to get all categories
     * @returns {object} response
     */
    getCountryCategories: async (countrySlug: string) => {
      const url = `/categories/getCountryCategories/${countrySlug}`;
      const response = await requestor({
        method: 'GET',
        url,
      });

      return response;
    },

    /**
     * @name getCategoryById
     * @description Function used to make request to get a category by an id
     * @param {string} id
     * @returns {Promise<any>} response
     */
    getCategoryById: async (categoryId: string) => {
      const url = `/categories/${categoryId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
  },
});
