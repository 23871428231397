import React, { useState } from 'react';
import moment from 'moment';
import { LuVerified } from 'react-icons/lu';
import { RxExternalLink } from 'react-icons/rx';
import { useAgents } from '../../../../hooks';
import DashboardLoader from '../../sections/Loader/DashboardLoader';
import { DAV_APIS } from '../../../../adapters';
import { AgentProps } from '../../../../constants';
import { Alert } from '../../../commons';
import classes from './styles.module.css';

const Agents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState<number | null>(null);
  const [selectedAgent, setSelectedAgent] = useState({} as AgentProps);
  const { agents, loading } = useAgents(refresh);

  const handleActivateDeactivate = async (agent: AgentProps) => {
    setSelectedAgent(agent);
    setIsLoading(true);
    try {
      const res = await DAV_APIS.agents.activateDeactivateAgent(
        agent.id,
        agent.isActive ? 'deactivate' : 'activate',
      );
      if (res.status === 200) {
        Alert({ message: 'Updated Successfully', variant: 'success' });
      }
      setRefresh(Math.random());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Alert({ message: 'Failed to update agent', variant: 'error' });
    }
  };

  const getButtonLabel = (isActive: boolean, id: string) => {
    if (isLoading && selectedAgent.id === id) {
      if (isActive) return 'Deactivating...';
      return 'Activating...';
    }
    if (isActive) {
      return 'Deactivate';
    }
    return 'Activate';
  };

  return (
    <div className='w-full p-5 my-3'>
      <h1 className='text-2xl font-semibold my-2 italic'>Manage Agents</h1>
      <div className='py-5'>
        <div className={classes.dav_manage_agents_wrapper}>
          {loading && agents.length < 1 ? (
            <DashboardLoader />
          ) : (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
              {agents.length > 0 ? (
                agents?.map((agent) => {
                  const {
                    username,
                    email,
                    phoneNumber,
                    companyName,
                    companyWebsite,
                    country,
                    isActive,
                    createdAt,
                    id,
                  } = agent;
                  return (
                    <div className='bg-white rounded-md hover:shadow-md' key={id}>
                      <div className={classes.dav_manage_agent_card_inner}>
                        <div className={classes.dav_manage_agent_card_header}>
                          <h4 className='text-primary capitalize'>{username}</h4>
                          {isActive && <LuVerified className='text-3xl text-primary' />}
                        </div>
                        <div className={classes.dav_manage_agent_card_body}>
                          <a href={`mailto:${email}`} className='hover:text-primary'>
                            {email}
                          </a>
                          <div className={classes.dav_manage_agent_card_body_content}>
                            <span>Tel. Number</span>: {phoneNumber}
                          </div>
                          <div className={classes.dav_manage_agent_card_body_content}>
                            <span>Co. Name</span>: {companyName}
                          </div>

                          <div className={classes.dav_manage_agent_card_body_content}>
                            <span>Country</span>: {country}
                          </div>
                          <div className={classes.dav_manage_agent_card_body_content}>
                            <span>Joined On</span>: {moment(createdAt).format('MMMM Do YYYY')}
                          </div>
                          <div className='flex items-center gap-2'>
                            <span className='text-primary'>Company Website:</span>
                            <a href={companyWebsite} target='_blank' rel='noreferrer'>
                              <RxExternalLink className='text-2xl' />
                            </a>
                          </div>
                        </div>
                        <div className={classes.dav_manage_agent_card_footer}>
                          <button
                            className='w-full bg-white text-primary border border-solid border-primary hover:bg-primary hover:text-white hover:border-primary py-3 transition-all duration-500 disabled:bg-primary/80 disabled:text-white disabled:border-primary/80'
                            onClick={() => handleActivateDeactivate(agent)}
                            type='button'
                            disabled={isLoading && selectedAgent.id === id}
                          >
                            {getButtonLabel(isActive, id)}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={classes.dav_manage_agents_no_agents}>
                  <div>No Agents Registered Yet!</div>
                  <p>
                    All agents will appear here and you will be able to activate and deactivate them
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Agents;
