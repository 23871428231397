import { useState, useEffect } from 'react';
import { DAV_APIS } from '../adapters';
import { CategoryProps } from '../constants';

let categoriesData: CategoryProps[] = [];

const useCountryCategories = (countrySlug: string, refresh?: number | null) => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<CategoryProps[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (!countrySlug) {
          setIsLoading(false);
          return;
        }
        if (categoriesData.length > 0 && !refresh) {
          setCategories(categoriesData);
          setIsLoading(false);
        }
        setIsLoading(true);
        const res = await DAV_APIS.country.get.getCountryCategories(countrySlug);
        if (res.status === 200) {
          setCategories(res.data.categories);
          categoriesData = res.data.categories;
        }
        setIsLoading(false);
      } catch (error) {
        setCategories([]);
        setIsLoading(false);
      }
    };
    fetchCategories();
  }, [countrySlug, refresh]);
  return { categories, loading: isLoading };
};
export default useCountryCategories;
